import React, { useRef, useState } from "react";
import {
	Bubble,
	Composer,
	GiftedChat,
	Send,
	InputToolbar,
} from "react-native-gifted-chat";
import { View } from "react-native-web";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { TypingAnimation } from "react-native-typing-animation";
import IconButton from "@material-ui/core/IconButton";
import SendIcon from "@material-ui/icons/Send";
import { chatSubmit } from "../actions/chatActions";
import { colors } from "../globalColors";
import Button from "./Button";
import { noHTML } from "./HTMLParser";
import DropDown from "./DropDown";
import TextInput from "./TextInput";
import RadioButtons from "./RadioButtons";
import Checkboxes from "./Checkboxes";
import { clearState } from "../actions/sharedActions";
import Video from "./Video";

const ChatBox = ({ messages, messageType }) => {
	const [showOtherTextbox, setShowOtherTextbox] = useState(false);
	const [userResponse, setUserResponse] = useState("");
	const [other, setOther] = useState("");
	const [checkboxStatus, setCheckboxStatus] = useState({});
	const chatState = useSelector((state) => state.chatReducer);
	const dispatch = useDispatch();
	const giftedChat = useRef();
	let history = useHistory();
	let surveyModeAnswers;

	if (messageType === "checkboxes") {
		const handleSubmit = () => {
			const userResponse = Object.keys(checkboxStatus).filter(
				(option) => checkboxStatus[option]
			);
			dispatch(chatSubmit(userResponse));

			setCheckboxStatus({});
		};

		surveyModeAnswers = (
			<div
				style={{
					width: "calc(100% - 115px)",
					display: "flex",
					marginLeft: 55,
					flexDirection: "column",
				}}
			>
				<Checkboxes
					options={chatState.checkboxes}
					onChange={(e) => {
						setCheckboxStatus({
							...checkboxStatus,
							[e.target.getAttribute("name")]: e.target.checked,
						});
					}}
					chatMode={true}
				/>
				<Button
					onClick={handleSubmit}
					text="Continue"
					style={{ marginTop: 5, maxWidth: 150 }}
				/>
			</div>
		);
	} else if (messageType === "surveyMode") {
		if (chatState.answers.length > 8) {
			surveyModeAnswers = (
				<div
					style={{
						width: "calc(100% - 115px)",
						display: "flex",
						marginLeft: 55,
					}}
				>
					<DropDown
						value={userResponse}
						onChange={(e) => {
							if (e.target.value === "Other") {
								setUserResponse("Other");
								setShowOtherTextbox(true);
							} else {
								setShowOtherTextbox(false);
								dispatch(chatSubmit(e.target.value));
								setOther("");
								setUserResponse("");
							}
						}}
						options={chatState.answers}
						optionValue="optionName"
						chatMode={true}
					/>
					{showOtherTextbox && (
						<>
							<TextInput
								value={other}
								onChange={(e) => setOther(e.target.value)}
								style={{
									marginLeft: 10,
									marginRight: 10,
									borderColor: colors.primary,
								}}
								chatMode={true}
								label="Other"
							/>
							<IconButton
								style={{
									backgroundColor: colors.primary,
									color: colors.fontColorLight,
									padding: 8,
									fontSize: "1rem",
									height: 50,
									width: 50,
								}}
								disabled={!other}
								onClick={() => {
									dispatch(chatSubmit(other));
									setOther("");
									setUserResponse("");
									setShowOtherTextbox(false);
								}}
							>
								<SendIcon style={{ fontSize: "1.15rem" }} />
							</IconButton>
						</>
					)}
				</div>
			);
		} else {
			surveyModeAnswers = (
				<div style={{ marginTop: 10, marginLeft: 55 }}>
					<RadioButtons
						onChange={(e) => {
							if (e.target.value === "Other") {
								setShowOtherTextbox(true);
							} else {
								setShowOtherTextbox(false);

								setUserResponse(e.target.value);
								dispatch(chatSubmit(other || e.target.value));
							}
						}}
						options={chatState.answers}
						optionValue="optionName"
						optionKey="value"
						chatMode={true}
					/>
					{showOtherTextbox && (
						<>
							<TextInput
								value={other}
								onChange={(e) => setOther(e.target.value)}
								style={{
									borderColor: colors.primary,
									width: "calc(100% - 60px)",
									marginRight: 10,
								}}
								chatMode={true}
								label="Other"
							/>
							<IconButton
								style={{
									backgroundColor: colors.primary,
									color: colors.fontColorLight,
									padding: 8,
									fontSize: "1rem",
									height: 50,
									width: 50,
								}}
								disabled={!other}
								onClick={() => {
									dispatch(chatSubmit(other));
									setOther("");
									setUserResponse("");
									setShowOtherTextbox(false);
								}}
							>
								<SendIcon style={{ fontSize: "1.15rem" }} />
							</IconButton>
						</>
					)}
				</div>
			);
		}
	}

	return (
		<View style={{ width: "80%", height: 550 }}>
			<GiftedChat
				messages={messages}
				onSend={(newMessage) => {
					dispatch(chatSubmit(newMessage));
				}}
				user={{
					_id: 1,
				}}
				inverted={false}
				renderTime={() => null}
				renderFooter={() => {
					if (messageType === "surveyMode" || messageType === "checkboxes") {
						return surveyModeAnswers;
					}
					return chatState.isTyping ? (
						<View
							style={{
								backgroundColor: colors.chat.avaMessageBackground,
								borderRadius: 5,
								marginLeft: 53,
								height: 30,
								width: 75,
							}}
						>
							<TypingAnimation
								style={{ marginLeft: 20, marginTop: 5 }}
								dotMargin={8}
								dotAmplitude={2}
								dotSpeed={0.15}
								dotRadius={4}
								dotColor={colors.chat.avaMessageFontColor}
							/>
						</View>
					) : null;
				}}
				renderBubble={(props) => {
					const videoMessage = props.currentMessage.text === " ";
					return (
						<div>
							<Bubble
								{...props}
								wrapperStyle={{
									left: {
										backgroundColor: colors.chat.avaMessageBackground,
										marginBottom: videoMessage ? -50 : 5,
									},
									right: {
										backgroundColor: colors.chat.userMessageBackground,
										border: `2px solid ${colors.chat.userMessageBorderColor}`,
										marginBottom: 5,
									},
								}}
								textStyle={{
									left: {
										color: colors.chat.avaMessageFontColor,
										fontFamily: "Montserrat, sans-serif",
										fontSize: 18,
										padding: 10,
									},
									right: {
										color: colors.chat.userMessageFontColor,
										fontFamily: "Montserrat, sans-serif",
										fontSize: 18,
										padding: 10,
									},
								}}
							/>
						</div>
					);
				}}
				renderInputToolbar={(props) => {
					if (chatState.messageType === "statement") {
						if (!chatState.isTyping) {
							return (
								<View style={{ alignSelf: "center" }}>
									<Button
										text={noHTML(chatState.statementButton)}
										onClick={async () => {
											if (
												chatState.shouldEndSession ||
												chatState.statementButton.includes("Dashboard")
												//	MZM 1-15-21 Not thrilled about adding this includes fix but it was possible for users to get the go to dashboard button without shouldEndSession getting set to true
											) {
												dispatch(clearState("chat"));
												return history.push("/loading");
											}
											dispatch(chatSubmit(""));
										}}
									/>
								</View>
							);
						}
					} else if (
						messageType === "surveyMode" ||
						messageType === "checkboxes"
					) {
						return null;
					} else {
						return (
							<InputToolbar
								{...props}
								containerStyle={{
									marginTop: 10,
									border: `2px solid ${colors.chat.chatInputBorderColor}`,
									borderRadius: 100,
								}}
							/>
						);
					}
				}}
				renderComposer={(props) => (
					<Composer
						{...props}
						textInputAutoFocus={true}
						multiline={false}
						textInputStyle={{
							color: colors.chat.chatInputFontColor,
							backgroundColor: colors.chat.chatInputBackground,
							padding: 20,
							fontFamily: "Montserrat, sans-serif",
						}}
						textInputProps={{
							...props.textInputProps,
							ref: giftedChat,
							onSubmitEditing: () => {
								if (props.text && props.onSend) {
									props.onSend({ text: props.text.trim() }, true);
								}
								setTimeout(() => giftedChat?.current?.focus(), 100);
							},
						}}
					/>
				)}
				placeholder="Type your response here..."
				renderSend={(props) => (
					<Send
						{...props}
						alwaysShowSend={
							!(
								chatState.messageType === "statement" ||
								chatState.shouldEndSession
							)
						}
						containerStyle={{
							border: 0,
							justifyContent: "center",
							alignItems: "center",
							padding: 20,
							height: 50,
						}}
					>
						<IconButton
							style={{
								backgroundColor: colors.primary,
								color: colors.fontColorLight,
								padding: 8,
								fontSize: "1rem",
							}}
						>
							<SendIcon style={{ fontSize: "1.15rem" }} />
						</IconButton>
					</Send>
				)}
				listViewProps={{
					style: {
						backgroundColor: colors.chat.chatBackground,
						marginBottom: 20,
					},
				}}
				renderMessageVideo={(props) => {
					return (
						<View>
							<Video
								video={props.currentMessage.video}
								childStyle={{ minWidth: 400 }}
							/>
						</View>
					);
				}}
			/>
		</View>
	);
};

export default ChatBox;

import React, { useState } from "react";
import styled from "styled-components";
import { useMountEffect } from "../hooks/useMountEffect";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import API from "../API";
import { HTMLText, noHTML } from "../Components/HTMLParser";
import { getContent } from "../shared";
import { updateState } from "../actions/sharedActions";
import Loading from "../Components/Loading";
import Button from "../Components/Button";
import Grid from "@material-ui/core/Grid";
import Container from "../Components/FlexContainer";
import AvaAvatar from "../images/7.png";
import SurveyModeIcon from "../images/SurveyIcon.svg";
import VoiceModeIcon from "../images/VoiceIcon.svg";
import TodoModeIcon from "../images/TodoIcon.svg";
import ChatModeIcon from "../images/ChatIcon.svg";
import { colors } from "../globalColors";
import Headline, { SmallHeadline } from "../Components/Headline";

const ModeCardStyle = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 20px;
	border-width: 1px;
	border-style: solid;
	border-radius: 5px;
	width: calc(100% - 40px);
	height: 300px;
	:hover {
		cursor: pointer;
	}
	p {
		text-align: center;
	}
`;

const ModeCard = ({ image, headline, body, active = false }) => (
	<ModeCardStyle
		style={
			active
				? { borderColor: "rgb(0, 158, 226)" }
				: { borderColor: colors.dashboardTileBorder }
		}
	>
		<img
			src={image}
			alt={headline}
			style={{ width: "125px", padding: "15px 0" }}
		/>
		<SmallHeadline style={{ textAlign: "center" }}>{headline}</SmallHeadline>
		{body}
	</ModeCardStyle>
);

const ModeSelection = () => {
	const [content, setContent] = useState();
	const [mode, setMode] = useState(null);
	const dispatch = useDispatch();

	useMountEffect(() => {
		(async () => {
			getContent(10, true).then(setContent);
			await API(`/user/userData`, "POST", {
				attributeName: "First Visit Mode Choice Seen",
				attributeValue: "Yes",
				sessionFlag: true,
			});
		})();
	});

	if (!content) {
		return <Loading />;
	}

	return (
		<Container>
			<div style={{ width: "75%" }}>
				<Grid container alignItems="center" spacing={3}>
					<Grid item xs={2}>
						<img src={AvaAvatar} alt="ava-avatar" style={{ width: "100%" }} />
					</Grid>
					<Grid item xs={10}>
						<Headline>{noHTML(content.headline)}</Headline>
						<p style={{ marginTop: 15 }}>{HTMLText(content.statement)}</p>
					</Grid>
					<Grid item xs={12} style={{ margin: "25px 0px" }}>
						<hr style={{ border: `1px solid ${colors.dashboardTileBorder}` }} />
					</Grid>
				</Grid>
			</div>
			<div style={{ width: "69%" }}>
				<Grid container spacing={1}>
					<Grid item xs={3} onClick={() => setMode("survey")}>
						<ModeCard
							image={SurveyModeIcon}
							headline={noHTML(content.surveyModeLabel)}
							body={HTMLText(content.surveyModeInstructions)}
							active={mode === "survey"}
						/>
					</Grid>
					<Grid item xs={3} onClick={() => setMode("chat")}>
						<ModeCard
							image={ChatModeIcon}
							headline={noHTML(content.chatModeLabel)}
							body={HTMLText(content.chatModeInstructions)}
							active={mode === "chat"}
						/>
					</Grid>
					<Grid item xs={3} onClick={() => setMode("voice")}>
						<ModeCard
							image={VoiceModeIcon}
							headline={noHTML(content.voiceModeLabel)}
							body={HTMLText(content.voiceModeInstructions)}
							active={mode === "voice"}
						/>
					</Grid>
					<Grid item xs={3} onClick={() => setMode("toDoList")}>
						<ModeCard
							image={TodoModeIcon}
							headline={noHTML(content.toDoListLabel)}
							body={HTMLText(content.toDoListInstructions)}
							active={mode === "toDoList"}
						/>
					</Grid>
					{mode !== null && (
						<Link to={`/${mode}`}>
							<Button
								text="Continue"
								onClick={() =>
									dispatch(updateState("auth", { userMode: mode }))
								}
							/>
						</Link>
					)}
				</Grid>
			</div>
		</Container>
	);
};

export default ModeSelection;

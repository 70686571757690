import React from "react";
import styled from "styled-components";
import TextField from "@material-ui/core/TextField";
import { colors } from "../globalColors";

const StyledInput = styled(TextField)`
	${({ theme }) => `
	color: ${theme.palette.fonts.dark};
	.MuiFilledInput-root {
    background-color: ${colors.inputs.inputBackground};
    border-bottom: 1px solid ${theme.palette.primary.main};
   }
  .MuiFilledInput-underline {
    background-color: ${colors.inputs.inputBackground};
    border-bottom: 1px solid ${theme.palette.primary.main};
    :hover:before, :before {
       border-bottom: inherit;
    }
   }
`}
`;

const TextInput = ({ chatMode = false, ...props }) => (
	<StyledInput
		{...props}
		variant={chatMode ? "outlined" : "filled"}
		fullWidth
	/>
);

export default TextInput;

const initialState = {
	chat: {
		messages: [],
		isTyping: false,
	},
	survey: {},
	voice: {
		blobURL: null,
	},
	ui: {
		snackbarOpen: false,
		snackbarMessage: "",
		modalOpen: false,
		modalContent: null,
	},
	auth: {
		init: false,
		loading: false,
		user: {},
		company: null,
		isSignedIn: false,
		accessMap: {
			isAdmin: false,
			isSuperAdmin: false,
		},
		error: false,
		registration: {
			verified: false,
			loading: false,
			error: false,
		},
		returnUrl: null,
		userMode: null,
	},
};

export const updateStateHOC = (reducerName) => {
	return (state = initialState[reducerName], { type, payload, name }) => {
		if (name !== reducerName) {
			return state;
		}
		switch (type) {
			case "UPDATE_STATE":
				return {
					...state,
					...payload,
					error: !!payload.error,
				};
			case "UPDATE_REGISTRATION":
				return {
					...state,
					registration: {
						...state.registration,
						...payload,
						error: !!payload.error,
						loading: false,
					},
				};
			case "OVERRIDE_STATE":
				return { ...payload };
			case "CLEAR_STATE":
				return initialState[reducerName];
			default:
				console.error(`Reducer type "${type}" does not exist`);
				return state;
		}
	};
};

import React, { useState } from "react";
import { useMountEffect } from "../hooks/useMountEffect";
import { getContent } from "../shared";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";

const FooterContainer = styled.footer`
	margin-top: 20px;
	margin-right: 25px;
`;
const Footer = () => {
	const [footerTitle, setFooterTitle] = useState(null);
	useMountEffect(() => {
		(async () => {
			getContent(7461).then(setFooterTitle);
		})();
	});

	return (
		<FooterContainer>
			<Grid container>
				<Grid item xs={4}></Grid>
				<Grid item xs={4} style={{ textAlign: "center" }}>
					<p>v. {process.env.REACT_APP_VERSION}</p>
				</Grid>
				<Grid item xs={4}>
					{footerTitle !== null && (
						<p style={{ textAlign: "right" }}>{footerTitle.footer}</p>
					)}
				</Grid>
			</Grid>
		</FooterContainer>
	);
};

export default Footer;

import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Loading from "./Loading";
import MaterialButton from "@material-ui/core/Button";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";

const Links = ({ list, linksNumber }) => {
	const [clicked, setClicked] = useState(null);

	if (!list[1]) {
		return <Loading />;
	}

	// let spacing = 0;
	let cardSize = 4;
	if (linksNumber === 1) {
		cardSize = 6;
	} else if (linksNumber === 2) {
		cardSize = 6;
	}

	return (
		<Grid container spacing={3}>
			{/*{spacing !== 0 ? <Grid item xs={spacing} /> : null}*/}
			{["1", "2", "3"].map((number) => {
				const link = list[number];
				if (!link.URL) {
					return null;
				}
				return (
					<Grid
						item
						xs={cardSize}
						style={{ cursor: "pointer" }}
						onClick={() => {
							window.open(link.URL);
						}}
					>
						{link.Image ? (
							<img
								src={link.Image}
								style={{
									width: "100%",
									height: 100,
									objectFit: "cover",
									borderTopLeftRadius: 15,
									borderTopRightRadius: 15,
									background: "white",
								}}
								alt={`Link ${number}`}
							/>
						) : (
							<div
								style={{
									width: "100%",
									height: 100,
									borderTopLeftRadius: 15,
									borderTopRightRadius: 15,
									background:
										"linear-gradient(45deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,158,226,1) 100%)",
								}}
							/>
						)}
						<div
							style={{
								borderBottomLeftRadius: 15,
								borderBottomRightRadius: 15,
								padding: 20,
								paddingBottom: 10,
								background: "white",
								marginTop: -10,
								height: "calc(100% - 120px)",
							}}
						>
							{link.Favicon || link["Source Name"] ? (
								<Grid container spacing={1}>
									{link.Favicon ? (
										<Grid item xs={1}>
											<img
												src={link.Favicon}
												style={{ width: "100%" }}
												alt={`Link ${number} favicon`}
											/>
										</Grid>
									) : null}
									<Grid item xs={link.Favicon ? 11 : 12}>
										{link["Source Name"]}
									</Grid>
								</Grid>
							) : null}
							{link.Headline ? (
								<p style={{ fontWeight: 700, paddingTop: 10 }}>
									{link.Headline}
								</p>
							) : null}
							{link.Introduction ? (
								<p style={{ paddingTop: 5 }}>{link.Introduction}</p>
							) : null}
							<div
								style={{
									display: "flex",
									justifyContent: "space-between",
									// height: "calc(15% - 16px)",
									marginLeft: -7,
									paddingTop: 10,
								}}
							>
								<div>
									<a href={link} target="_blank" rel="noreferrer">
										<MaterialButton
											color="primary"
											style={{ textTransform: "none" }}
										>
											Read More
										</MaterialButton>
									</a>
								</div>
								<div style={{ padding: 6 }}>
									<IconButton onClick={() => setClicked(true)} size="small">
										<ThumbUpIcon
											color={!clicked ? "primary" : "secondary"}
											fontSize="inherit"
										/>
									</IconButton>
									<IconButton onClick={() => setClicked(false)} size="small">
										<ThumbDownIcon
											color={
												clicked === null || clicked ? "primary" : "secondary"
											}
											fontSize="inherit"
										/>
									</IconButton>
								</div>
							</div>
						</div>
					</Grid>
				);
			})}
		</Grid>
	);
};

export default Links;

import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { chatSubmit } from "../actions/chatActions";
import { surveySubmit } from "../actions/surveyActions";
import { voiceSubmit } from "../actions/voiceActions";
import { HTMLText, noHTML } from "./HTMLParser";
import Button from "./Button";
import { clearState } from "../actions/sharedActions";
import ReactPlayerLoader from "@brightcove/react-player-loader";
import Video from "./Video";

/**
 * @component StatementView - Used for statement message types across all modes
 */

const StatementHeadline = styled.h1`
	font-weight: 600;
	font-size: 20px;
	line-height: 32px;
`;

const StatementView = ({
	question,
	buttonText,
	shouldEndSession = false,
	mode,
	video,
}) => {
	const dispatch = useDispatch();
	let history = useHistory();

	const handleSubmit = () => {
		if (mode === "chat") {
			dispatch(chatSubmit(""));
		} else if (mode === "voice") {
			dispatch(voiceSubmit(""));
		} else {
			dispatch(surveySubmit("", mode));
		}
	};

	const handleEndSession = async () => {
		dispatch(clearState("survey"));
		history.push("/loading");
	};

	return (
		<div style={{ width: "69%", paddingTop: 15 }}>
			<StatementHeadline>{HTMLText(question)}</StatementHeadline>
			<Video video={video} />
			{shouldEndSession ? (
				<Button text={noHTML(buttonText)} onClick={handleEndSession} />
			) : (
				<Button text={noHTML(buttonText)} onClick={handleSubmit} />
			)}
		</div>
	);
};

export default StatementView;

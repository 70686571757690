import React, { useState } from "react";
import { useMountEffect } from "../hooks/useMountEffect";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import API from "../API";
import { updateState } from "../actions/sharedActions";
import Loading from "../Components/Loading";
import Container from "../Components/FlexContainer";
import { CheckInTile } from "../Components/DashboardTiles";
import { SmallHeadline } from "../Components/Headline";
import CTAImage from "../images/dashboardCTAImage.svg";
import Offers from "../Components/Offers";
import Tips from "../Components/Tips";
import Links from "../Components/Links";
import Goals from "../Components/Goals";
import ToDos from "../Components/ToDos";
import { colors } from "../globalColors";

const Dashboard = () => {
	const userMode = useSelector((state) => state.auth.userMode);
	const [content, setContent] = useState(null);
	const [links, setLinks] = useState(null);
	const [tips, setTips] = useState(null);
	const [goals, setGoals] = useState(null);
	const [toDos, setToDos] = useState(null);
	const [totalCards, setTotalCards] = useState(0);
	const dispatch = useDispatch();
	let history = useHistory();

	useMountEffect(() => {
		(async () => {
			const userData = await API(
				`/user/userData/Current Interaction Mode`,
				"GET"
			);
			userData?.attributeValue &&
				dispatch(updateState("auth", { userMode: userData.attributeValue }));

			const content = await API(`/content/dashboard`, "GET");
			if (content.firstSession || !userMode) {
				history.push("/welcome");
			} else {
				breakOutContent(content.content);
			}
		})();
	});

	const breakOutContent = async (contentObject) => {
		setContent(contentObject);
		const links = { 1: {}, 2: {}, 3: {} };
		const tips = { 1: {}, 2: {}, 3: {} };
		const goals = {};
		const toDos = { 1: {}, 2: {}, 3: {}, content: {} };
		Object.entries(contentObject).forEach(([element, value]) => {
			["1", "2", "3"].forEach((number) => {
				if (element.includes(number) && element.includes("Tip")) {
					tips[number][element.replace(`Tip ${number}`, "").trim()] = value;
				} else if (element.includes(number) && element.includes("To Do")) {
					toDos[number][element.replace(`To Do ${number}`, "").trim()] = value;
				} else if (
					element.includes(number) &&
					element.includes("Link") &&
					!element.includes("Offer")
				) {
					links[number][element.replace(`Link ${number}`, "").trim()] = value;
				}
			});
			if (element.includes("Your Goals")) {
				goals[element] = value;
			} else if (element.includes("To Do List")) {
				toDos["content"][element] = value;
			} else if (element === "Show Goals") {
				if (value?.toLowerCase() === "no") {
					goals.hide = true;
				} else {
					goals.hide = false;
				}
			}
		});
		contentObject["Links Number"] &&
			contentObject["Links Number"] !== "0" &&
			setLinks(links);
		contentObject["Tips Number"] &&
			contentObject["Tips Number"] !== "0" &&
			setTips(tips);
		contentObject["Your Goals Card Headline"] && setGoals(goals);
		contentObject["To Dos Number"] &&
			contentObject["To Dos Number"] !== "0" &&
			setToDos(toDos);

		let cardCounter = 1; //start with the check in tile
		if (toDos) {
			cardCounter++;
		}
		if (goals && goals?.hide === false) {
			cardCounter++;
		}
		// if (links) {
		// 	cardCounter++;
		// }
		if (tips) {
			cardCounter++;
		}
		setTotalCards(cardCounter);
	};

	if (!content || !userMode) {
		return <Loading />;
	}

	return (
		<Container style={{ backgroundColor: colors.dashboardBackground }}>
			<Grid container spacing={3} style={{ width: "80%" }}>
				<Grid item xs={tips ? 6 : 12}>
					<CheckInTile
						headline={content["Call to Action Headline"]}
						image={content["Call to Action Image"] || CTAImage}
						body={content["Call to Action Instructions"]}
						button={
							content["Call to Action Button Label"] || "Start next session"
						}
						buttonLink={userMode ? `/${userMode}` : "/welcome"}
					/>
				</Grid>
				{toDos && (
					<Grid item xs={6}>
						<ToDos list={toDos} />
					</Grid>
				)}
			</Grid>
			<Container
				style={{
					marginTop: 30,
					padding: 20,
					borderTop: `7px solid ${colors.dashboardBlueStrip}`,
					backgroundColor: colors.offerBackground,
				}}
			>
				{content["Offer 1 ID"] && (
					<div style={{ width: "80%" }}>
						<SmallHeadline>{content.offersHeadline || "For you"}</SmallHeadline>
						<Offers content={content} />
					</div>
				)}
			</Container>
			<Grid
				container
				spacing={3}
				style={{ width: "80%", paddingTop: 30, paddingBottom: 30 }}
			>
				{goals && goals?.hide === false && (
					<Grid item xs={6}>
						<Goals list={goals} />
					</Grid>
				)}
				{tips && (
					<Grid item xs={totalCards % 2 === 0 ? 6 : 12}>
						<Tips list={tips} content={content} />
					</Grid>
				)}
			</Grid>
			{links && (
				<Container
					style={{
						marginTop: 30,
						padding: 20,
						borderTop: `7px solid ${colors.dashboardBlueStrip}`,
						backgroundColor: colors.offerBackground,
					}}
				>
					<div style={{ width: "80%" }}>
						<SmallHeadline>{content.linksHeadline || "Links"}</SmallHeadline>
						<Links
							list={links}
							linksNumber={parseInt(content["Links Number"])}
						/>
					</div>
				</Container>
			)}
		</Container>
	);
};

export default Dashboard;

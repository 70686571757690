import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { closeSnackbar } from "../actions/uiActions";

const SnackbarAlert = () => {
	const dispatch = useDispatch();
	const uiState = useSelector((state) => state.uiReducer);

	const Alert = (props) => {
		return <MuiAlert elevation={6} variant="filled" {...props} />;
	};

	return (
		uiState.snackbarOpen && (
			<Snackbar
				open={uiState.snackbarOpen}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				onClose={() => dispatch(closeSnackbar())}
				autoHideDuration={uiState.alertType === "error" ? 10000 : 3000}
			>
				<Alert
					severity={uiState.alertType}
					onClose={() => dispatch(closeSnackbar())}
				>
					{uiState.snackbarMessage}
				</Alert>
			</Snackbar>
		)
	);
};

export default SnackbarAlert;

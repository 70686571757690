import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import styled from "styled-components";
import { Provider as ReduxProvider } from "react-redux";
import store from "./store";
import { ThemeProvider } from "styled-components";
import {
	createMuiTheme,
	StylesProvider,
	ThemeProvider as MuiThemeProvider,
} from "@material-ui/core/styles";
import SnackbarAlert from "./Components/Snackbar";
import { colors } from "./globalColors";
import "./global.scss";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import "url-search-params-polyfill";

const MainContainer = styled.main`
	min-height: calc(100vh - 54px);
	@media (min-width: 600px) and (max-width: 959px), (min-width: 960px) {
		min-height: calc(100vh - 54px);
	}
`;

const theme = createMuiTheme({
	palette: {
		primary: { main: colors.primary },
		secondary: { main: colors.secondary },
		accent: { main: colors.accent },
		fonts: {
			light: colors.fontColorLight,
			dark: colors.fontColorDark,
		},
	},
	typography: {
		fontFamily: ["Montserrat", "sans-serif"].join(","),
	},
});

const App = () => {
	return (
		<ReduxProvider store={store}>
			<MuiThemeProvider theme={theme}>
				<ThemeProvider theme={theme}>
					<StylesProvider injectFirst>
						<MainContainer>
							<Router>
								<SnackbarAlert />
								<Header />
							</Router>
						</MainContainer>
						<Footer />
					</StylesProvider>
				</ThemeProvider>
			</MuiThemeProvider>
		</ReduxProvider>
	);
};

export default App;

import React, { useState } from "react";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { colors } from "../globalColors";
import { ListTile } from "./DashboardTiles";
import Loading from "./Loading";
import GoalImage from "../images/goals.png";

const StyledTab = styled(Tab)`
	&.MuiTab-root {
		text-transform: none;
		min-width: 50px;
		font-weight: 600;
	}
`;

const StyledDescription = styled.p`
	font-size: 0.8rem;
	color: ${colors.primary};
	font-weight: 400;
	margin: 5px 0px 15px 0px;
`;

const StyledGoalText = styled.p`
	font-size: 0.9rem;
	font-weight: 400;
`;

const TabPanel = ({ children, value, index }) => (
	<div role="tabpanel" hidden={value !== index}>
		{value === index && children}
	</div>
);

const Goals = ({ list }) => {
	const [tab, setTab] = useState(0);
	const imageMap = {
		0: list["Your Goals Short Term Image"],
		1: list["Your Goals Mid Term Image"],
		2: list["Your Goals Long Term Image"],
		3: list["Your Goals Care Path Image"],
	};

	if (!Object.keys(list).length) {
		return <Loading />;
	}
	return (
		<ListTile
			title={list["Your Goals Card Headline"]}
			titlePadding={5}
			titleLeftMargin={5}
		>
			<Grid container spacing={2} justify="center">
				<Grid container justify="center">
					<Grid item xs={12}>
						<Tabs
							value={tab}
							onChange={(e, newValue) => setTab(newValue)}
							indicatorColor="primary"
							variant="fullWidth"
						>
							<StyledTab label={list["Your Goals Short Term Tab Heading"]} />
							<StyledTab label={list["Your Goals Mid Term Tab Heading"]} />
							<StyledTab label={list["Your Goals Long Term Tab Heading"]} />
							<StyledTab label={list["Your Goals Care Path Tab Heading"]} />
						</Tabs>
					</Grid>
				</Grid>
				<Grid item xs={8}>
					<TabPanel value={tab} index={0}>
						<StyledDescription>
							{list["Your Goals Short Term Description"]}
						</StyledDescription>
						<StyledGoalText>
							{list["Your Goals Short Term Goal"]}
						</StyledGoalText>
					</TabPanel>
					<TabPanel value={tab} index={1}>
						<StyledDescription>
							{list["Your Goals Mid Term Description"]}
						</StyledDescription>
						<StyledGoalText>{list["Your Goals Mid Term Goal"]}</StyledGoalText>
					</TabPanel>
					<TabPanel value={tab} index={2}>
						<StyledDescription>
							{list["Your Goals Long Term Description"]}
						</StyledDescription>
						<StyledGoalText>{list["Your Goals Long Term Goal"]}</StyledGoalText>
					</TabPanel>
					<TabPanel value={tab} index={3}>
						<StyledDescription>
							{list["Your Goals Care Path Description"]}
						</StyledDescription>
						<StyledGoalText>{list["Your Goals Care Path Goal"]}</StyledGoalText>
					</TabPanel>
				</Grid>
				<Grid item xs={4}>
					<img
						src={imageMap[tab] || GoalImage}
						alt={list["Your Goals Card Headline"]}
						style={{ width: "100%" }}
					/>
				</Grid>
			</Grid>
		</ListTile>
	);
};

export default Goals;

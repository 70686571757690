export const colors = {
	primary: "#00A6EB",
	secondary: "#005E85",
	accent: "#79EDB0",
	fontColorLight: "#FFFFFF",
	fontColorDark: "#58595B",
	mainBackground: "#FFFFFF",
	dashboardBackground: "#F9FBFB",
	dashboardTileBorder: "#EBEBEB",
	offerBackground: "#E6E6E6",
	dashboardBlueStrip: "#A6E0F8",
	ctas: "#004455",
	coral: "#F58A78",
	lemon: "#FBDB65",
	lavender: "#D0CAFC",
	chat: {
		avaMessageBackground: "#EEF9FE",
		avaMessageFontColor: "#005E85",
		userMessageBackground: "#FFFFFF",
		userMessageFontColor: "#58595B",
		userMessageBorderColor: "#B1B3B6",
		chatInputBackground: "#FFFFFF",
		chatInputBorderColor: "#EBEBEB",
		chatInputFontColor: "#6D6E71",
		chatBackground: "#FCFCFC",
	},
	inputs: {
		inputBackground: "rgba(0, 166, 235, 0.08)",
		radioBorderColor: "#CCCCCC",
	},
	activeRecordingButton: "red",
};

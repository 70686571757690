import React, { useState } from "react";
import styled from "styled-components";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { colors } from "../globalColors";

const StyledCheckboxes = styled(FormGroup)`
	${({ theme }) => `
	label {
		display: block;
		padding: 10px;
		border: 1px solid ${colors.inputs.radioBorderColor};
		border-radius: 5px;
		margin-bottom: 10px;
		margin-left: 0px;
		cursor: pointer;
	}
	.MuiCheckbox-root {
		color: ${colors.inputs.radioBorderColor};
	}
	.Mui-checked {
		color: ${theme.palette.primary.main};
	}
`}
`;

const Checkboxes = ({ options, chatMode = false, ...props }) => {
	const [checked, setChecked] = useState({});

	return (
		<StyledCheckboxes>
			{options.map((option, index) => {
				let style = {};
				if (checked[option.label]) {
					style = {
						borderColor: colors.primary,
						backgroundColor: colors.inputs.inputBackground,
					};
				}
				if (chatMode) {
					style.paddingTop = 5;
					style.paddingBottom = 5;
				}

				return (
					<FormControlLabel
						control={
							<Checkbox
								{...props}
								checked={option.checked}
								name={option.label}
								onChange={(e) => {
									e.persist();
									props.onChange(e);
									setChecked({ ...checked, [option.label]: e.target.checked });
								}}
							/>
						}
						label={option.label}
						key={option.id || `${option.label}-${index}`}
						style={style}
					/>
				);
			})}
		</StyledCheckboxes>
	);
};

export default Checkboxes;

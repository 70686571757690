import React, { useState } from "react";
import { useMountEffect } from "../hooks/useMountEffect";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { getContent } from "../shared";
import Button from "../Components/Button";
import WelcomeBanner from "../images/WelcomeBanner.svg";
import Loading from "../Components/Loading";
import Container from "../Components/FlexContainer";
import { colors } from "../globalColors";
import API from "../API";

const WelcomeHero = styled.div`
	position: relative;
	width: 100%;
	img {
		width: 100%;
	}
	.text {
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		text-align: center;
		padding-top: 15px;
		font-size: 24px;
		font-weight: 600;
		line-height: 36px;
		span.white {
			color: ${colors.fontColorLight};
		}
		span.blue {
			color: ${colors.ctas};
		}
	}
`;

const WelcomeHeadline = styled.h1`
	${({ theme }) => `
    color: ${theme.palette.fonts.fontColorDark};
    font-weight: 700;
    font-size: 48px;
    line-height: 72px;
    max-width: 800px;
	`}
`;

const Body = styled.div`
	${({ theme }) => `
	max-width: 700px;
	p {
		color: ${theme.palette.fonts.fontColorDark};
    font-size: 22px;
    line-height: 36px;
    margin-top: 15px;
	}
	`}
`;

const Welcome = () => {
	const [content, setContent] = useState(null);

	useMountEffect(() => {
		getContent(7395).then(setContent);

		API("/auth/setTimezone", "POST", {});
	});

	if (!content) {
		return <Loading />;
	}

	return (
		<Container style={{ paddingTop: 0 }}>
			<WelcomeHero>
				<img src={WelcomeBanner} alt="Welcome" />
				{/*<div className="text">*/}
				{/*	<span className="white">Engagement</span>*/}
				{/*	<span className="blue">Rx</span>*/}
				{/*</div>*/}
			</WelcomeHero>
			<WelcomeHeadline>
				{content.headline || "Welcome to EngagementRx"}
			</WelcomeHeadline>
			<Body>
				<p>{content.body || ""}</p>
				<Link to="/mode">
					<Button text={content.buttonText || "Get started"} />
				</Link>
			</Body>
		</Container>
	);
};

export default Welcome;

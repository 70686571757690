import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { colors } from "../globalColors";
import LinearProgress from "@material-ui/core/LinearProgress";
import { withStyles } from "@material-ui/core/styles";
import API from "../API";

const LoadingPage = ({ match }) => {
	let history = useHistory();

	useEffect(() => {
		(async () => {
			await API("/user/endSession", "GET");
			history.push("/dashboard");
		})();
	}, []);

	const BorderLinearProgress = withStyles((theme) => ({
		root: {
			height: 10,
			borderRadius: 2,
		},
		colorPrimary: {
			backgroundColor: "#EEF9FE",
		},
		bar: {
			borderRadius: 2,
			backgroundColor: "#79EDB0",
		},
	}))(LinearProgress);

	return (
		<div
			style={{
				width: "100%",
				height: "calc(100vh - 115px)",
				background: colors.primary,
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				flexDirection: "column",
			}}
		>
			<h1 style={{ color: "white" }}>Generating Dashboard...</h1>
			<div style={{ width: "50%", marginTop: 30 }}>
				<BorderLinearProgress />
			</div>
		</div>
	);
};

export default LoadingPage;

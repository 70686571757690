import React from "react";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import { CircularProgress } from "@material-ui/core";

const BlueButton = styled(Button)`
	${({ theme }) => `
	background-color: ${theme.palette.primary.main};
	color: ${theme.palette.fonts.light};
	font-weight: 600;
	font-size: 18px;
	border-radius: 100px;
	min-width: 230px;
	height: 50px;
	padding: 15px;
	text-transform: none;
	box-shadow: none;
	&:hover, &:focus {
		background-color: ${theme.palette.secondary.main};
		box-shadow: none;
	}
	&:disabled {
		color: ${theme.palette.fonts.dark};
	}
	margin-top: 30px;
`}
`;

const StyledButton = ({ text, ...props }) => (
	<BlueButton {...props} variant="contained">
		{text}
	</BlueButton>
);

export const LoadingStyledButton = ({ text, loading, ...props }) => (
	<BlueButton {...props} variant="contained">
		{loading && <CircularProgress size={24} style={{ color: "#fff" }} />}
		{!loading && text}
	</BlueButton>
);

export default StyledButton;

import React, { useState, useEffect } from "react";
import { useMountEffect } from "../hooks/useMountEffect";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import saveAs from "file-saver";
import Fab from "@material-ui/core/Fab";
import BugReportIcon from "@material-ui/icons/BugReport";
import API from "../API";
import { HTMLText, noHTML } from "../Components/HTMLParser";
import { chatSubmit, startChat } from "../actions/chatActions";
import ChatBox from "../Components/ChatBox";
import CheckInHeader from "../Components/CheckInHeader";
import Container from "../Components/FlexContainer";
import { colors } from "../globalColors";
import LiveHelp from "@material-ui/icons/LiveHelp";
import { firebaseApp } from "../firebase";

const Chat = () => {
	const chatState = useSelector((state) => state.chatReducer);
	const { messages, messageType } = chatState;
	const dispatch = useDispatch();
	const [parsedMessages, setParsedMessages] = useState([]);
	const [combinedMessages, setCombinedMessages] = useState("");

	useMountEffect(() => {
		dispatch(startChat());
	});

	useEffect(() => {
		if (messages?.length) {
			const parsed = messages.map((message) => {
				return {
					...message,
					text:
						typeof message.text === "string"
							? HTMLText(message.text)
							: message.text,
				};
			});
			setParsedMessages(parsed);
		}

		return () => {};
	}, [messages]);

	useEffect(() => {
		if (messages?.length && messageType !== "question") {
			if (
				messages.length > 1 &&
				messages[messages.length - 2].user["_id"] === 2
			) {
				const lastTwo = messages.slice(-2);
				setCombinedMessages(lastTwo.map((message) => message.text).join(""));
			} else {
				const lastMessage = messages[messages.length - 1];
				setCombinedMessages(lastMessage.text);
			}
		}
	}, [messages, messageType]);

	const exportFile = async () => {
		const idArray = messages.map((message) => message.messageID);
		const { messageNames } = await API(
			`/content/messageIDNameConverter`,
			"POST",
			{
				idArray,
			}
		);

		const text = messages.map((message, index) => {
			return `${
				message.user.name === "TCS"
					? `TCS (${messageNames[index]}):\r\n`
					: "User:\r\n"
			}${noHTML(decodeURIComponent(message.text))}\r\n\r\n`;
		});

		const userAttributes = await API(`/user/userData`, "GET");

		const blob = new Blob(
			[
				`${text.join("")}\r\n\r\nAttributes:\r\n${JSON.stringify(
					userAttributes
				)}`,
			],
			{
				type: "text/plain;charset=utf-8",
			}
		);
		saveAs(
			blob,
			`Transcript and Attrs - ${moment().format("h:mm:ss a l")}.txt`
		);
	};

	const triggerPrompt = async () => {
		dispatch(chatSubmit(null, true));
	};

	return (
		<Container className="chatPage">
			<CheckInHeader currentMode="Chat" />
			<div
				style={{
					backgroundColor: colors.chat.chatBackground,
					width: "100%",
					display: "flex",
					justifyContent: "center",
					paddingBottom: 20,
				}}
			>
				{/* MZM 1/4/21
				I think this covers all message types, so we should be able to remove
				the conditional, but I'm leaving it because I'm tired & don't want to break
				something. */}
				{(messageType === "question" ||
					messageType === "statement" ||
					messageType === "surveyMode" ||
					messageType === "checkboxes") && (
					<ChatBox
						messages={parsedMessages}
						chatState={chatState}
						messageType={messageType}
						question={combinedMessages}
					/>
				)}
			</div>

			{firebaseApp?.auth()?.currentUser?.email.includes("@wearetuzag") && (
				<div
					onClick={exportFile}
					style={{
						cursor: "pointer",
						position: "fixed",
						bottom: 125,
						right: 15,
					}}
				>
					<Fab disabled aria-label="export">
						<BugReportIcon />
					</Fab>
				</div>
			)}
			<div
				onClick={triggerPrompt}
				style={{
					cursor: "pointer",
					position: "fixed",
					bottom: 40,
					right: 15,
				}}
			>
				<Fab aria-label="export" color="primary">
					<LiveHelp style={{ color: "white" }} />
				</Fab>
				<p style={{ fontSize: 14, marginTop: 5 }}>Prompt</p>
			</div>
			<p
				style={
					messageType === "statement" ? { paddingTop: 30 } : { paddingTop: 10 }
				}
			>
				Message: {chatState.currentMessageName}
			</p>
		</Container>
	);
};

export default Chat;

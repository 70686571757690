import React from "react";
import { useMountEffect } from "../hooks/useMountEffect";
import { useDispatch, useSelector } from "react-redux";
import { startSurvey } from "../actions/surveyActions";
import Loading from "../Components/Loading";
import SurveyView from "../Components/SurveyView";
import StatementView from "../Components/StatementView";
import CheckboxView from "../Components/CheckboxView";
import CheckInHeader from "../Components/CheckInHeader";
import Container from "../Components/FlexContainer";

// Currently, this reuses all of Survey's state/actions/etc. If ToDoList changes in the future, we'll need to break this up.

const ToDoList = () => {
	const surveyState = useSelector((state) => state.surveyReducer);
	const dispatch = useDispatch();

	useMountEffect(() => {
		dispatch(startSurvey("To Do List"));
	});

	if (!surveyState.messages?.length) {
		return <Loading />;
	}

	return (
		<Container>
			<CheckInHeader currentMode="To Do List" />
			{surveyState.messageType === "statement" && (
				<StatementView
					question={surveyState.messages.join("<br>")}
					buttonText={surveyState.statementButton}
					shouldEndSession={Boolean(surveyState.shouldEndSession)}
					mode="To Do List"
				/>
			)}
			{surveyState.messageType === "checkboxes" && (
				<CheckboxView
					question={surveyState.messages.join("<br>")}
					answers={surveyState.checkboxes}
					mode="To Do List"
				/>
			)}
			{(surveyState.messageType === "question" ||
				surveyState.messageType === "surveyMode") && (
				<SurveyView
					question={surveyState.messages.join("<br>")}
					answers={surveyState.answers}
					answerType={surveyState.answerType}
					mode="To Do List"
				/>
			)}
			<p style={{ paddingTop: 10 }}>
				Message: {surveyState.currentMessageName}
			</p>
		</Container>
	);
};

export default ToDoList;

import shortid from "shortid";
import API from "../API";
import { sendError, updateState } from "./sharedActions";
import AvaAvatar from "../images/AvaAvatar.png";

// Helpers
const randInt = (min, max) => {
	return Math.floor(Math.random() * (max - min + 1) + min);
};
const getNewMessages = async (start = false, message = null, startShapeID) => {
	if (message && Array.isArray(message)) {
		message = message[0].text;
	}
	let res = await API("/chat", "POST", {
		start,
		message,
		startShapeID,
		includeFormatting: true,
	});
	res.messages = JSON.parse(res.messages);
	res.messages.forEach((message) => (message.user["avatar"] = AvaAvatar));
	return res;
};
const formatUserMessage = (messageText) => {
	return {
		_id: shortid.generate(),
		createdAt: new Date(),
		text: Array.isArray(messageText) ? messageText.join(", ") : messageText,
		user: { _id: 1 },
	};
};

// Actions
const addMessage = (newMessage, stopTyping = false) => (dispatch, getState) => {
	if (newMessage !== "") {
		if (!newMessage[0].text) {
			newMessage = [formatUserMessage(newMessage)];
		}
		const prevMessages = getState().chatReducer.messages;
		dispatch(
			updateState("chat", { messages: [...prevMessages, ...newMessage] })
		);
		stopTyping && dispatch(updateState("chat", { isTyping: false }));
	}
};

const getMessageName = () => async (dispatch) => {
	const { name } = await API(`/user/currentMessageName`, "GET");
	return dispatch(updateState("chat", { currentMessageName: name }));
};

export const startChat = (startShapeID) => async (dispatch) => {
	dispatch(updateState("chat", { isTyping: true }));
	try {
		const res = await getNewMessages(true, null, startShapeID);
		dispatch(getMessageName());
		return dispatch(updateState("chat", { ...res, isTyping: false }));
	} catch (err) {
		dispatch(updateState("chat", { isTyping: false }));
		return dispatch(sendError(err, "chat"));
	}
};

export const chatSubmit = (newMessage, promptButton = false) => async (
	dispatch
) => {
	try {
		const sendTime = new Date();
		if (!promptButton) {
			newMessage && dispatch(addMessage(newMessage));
		}
		dispatch(updateState("chat", { isTyping: true }));

		const res = await getNewMessages(
			false,
			promptButton ? "prompt" : newMessage
		);
		dispatch(getMessageName());
		if (["checkboxes", "surveyMode"].includes(res.messageType)) {
			dispatch(addMessage([...res.messages]));
			return dispatch(updateState("chat", { ...res, isTyping: false }));
		}

		let waitTime = 0;
		for (const [index, message] of res.messages.entries()) {
			if (message?.text === "<br>") {
				continue;
			}
			if (message.text) {
				const thisMessageWaitTime = Math.max(
					message.text.length * randInt(10, 14) -
						Math.abs(new Date() - sendTime),
					0
				);
				waitTime += thisMessageWaitTime > 2000 ? 2000 : thisMessageWaitTime;
			} else {
				message.text = " ";
			}
			setTimeout(() => {
				dispatch(addMessage([message], index + 1 === res.messages.length));
			}, waitTime);
		}
		let toSet = { ...res };
		delete toSet.messages;
		return dispatch(updateState("chat", toSet));
	} catch (err) {
		dispatch(updateState("chat", { isTyping: false }));
		return dispatch(sendError(err, "chat"));
	}
};

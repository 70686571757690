import styled from "styled-components";

const Headline = styled.h1`
	${({ theme }) => `
		font-weight: 600;
		font-size: 24px;
		color: ${theme.palette.fonts.dark};
		`}
`;

export const SmallHeadline = styled.h2`
	${({ theme }) => `
		font-weight: 600;
		font-size: 20px;
		line-height: 32px;
		color: ${theme.palette.fonts.dark};
		padding-bottom: 15px;
	`}
`;

export default Headline;

import React, { useState } from "react";
import { useMountEffect } from "../hooks/useMountEffect";
import { useHistory } from "react-router-dom";
import camelcase from "camelcase";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import SettingsIcon from "@material-ui/icons/Settings";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Headline from "../Components/Headline";
import { getContent } from "../shared";
import { showSnackbar } from "../actions/uiActions";

const SubHead = styled.h3`
	${({ theme }) => `
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
		color: ${theme.palette.fonts.dark};
		padding-bottom: 10px;
	`}
`;

const ModeButton = styled(Button)`
	text-transform: none;
	font-size: 16px;
	padding-left: 0px;
`;

const CheckInHeader = ({ currentMode }) => {
	const [content, setContent] = useState(null);
	const [anchorElement, setAnchorElement] = useState(null);
	let history = useHistory();
	const dispatch = useDispatch();

	useMountEffect(() => {
		getContent(7398).then(setContent);
	});

	const modeList = ["Chat", "Survey", "Voice", "To Do List"].filter(
		(mode) => mode !== currentMode
	);

	const openModeChangeMenu = (e) => {
		setAnchorElement(e.currentTarget);
	};

	return (
		<div style={{ width: "69%", paddingBottom: "10px" }}>
			<Headline>{content?.headline || "Your Current Check-In"}</Headline>
			<SubHead>
				{content?.intro || "A quick check-in with Ava to brighten your day..."}
			</SubHead>
			<ModeButton
				variant="text"
				startIcon={<SettingsIcon style={{ fontSize: 22 }} />}
				color="primary"
				onClick={openModeChangeMenu}
			>
				{content?.changeCheckinMode || "Change check-in mode"}
			</ModeButton>
			<Menu
				anchorEl={anchorElement}
				open={Boolean(anchorElement)}
				onClose={() => setAnchorElement(null)}
				keepMounted
			>
				{modeList.map((mode, index) => (
					<MenuItem
						key={`${mode}-${index}`}
						onClick={(e) => {
							history.push(`/${camelcase(mode)}`);
							dispatch(
								showSnackbar(`Mode successfully changed to ${mode}`, "success")
							);
						}}
						style={{ padding: "16px 25px" }}
					>
						{mode}
					</MenuItem>
				))}
			</Menu>
		</div>
	);
};

export default CheckInHeader;

import React from "react";
import Grid from "@material-ui/core/Grid";
import { CardTile } from "./DashboardTiles";

const Offers = ({ content }) => {
	return (
		<Grid
			container
			direction="row"
			alignItems="center"
			justify="center"
			spacing={3}
		>
			{[1, 2, 3].map((index) => {
				if (!content[`Offer ${index} ID`]) {
					return null;
				}
				return (
					<Grid item xs={4} key={content[`Offer ${index} ID`]}>
						<CardTile
							title={content[`Offer ${index} Name`]}
							image={`https://storage.cloud.google.com/cdn.wearetuzag.com/offers/${
								content[`Offer ${index} ID`]
							}.${content[`Offer ${index} File Ext`]}`}
							body={content[`Offer ${index} Body`]}
							link={content[`Offer ${index} Link`]}
						/>
					</Grid>
				);
			})}
		</Grid>
	);
};

export default Offers;

import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import AccountCreation from "./Pages/AccountCreation";
import Chat from "./Pages/Chat";
import Dashboard from "./Pages/Dashboard";
import Login from "./Pages/Login";
import ModeSelection from "./Pages/ModeSelection";
import Register from "./Pages/Register";
import Survey from "./Pages/Survey";
import Voice from "./Pages/Voice";
import ToDoList from "./Pages/ToDo";
import Settings from "./Pages/Settings";
import Welcome from "./Pages/Welcome";
import LoadingPage from "./Pages/Loading";

const AuthenticatedRoute = ({ children, ...rest }) => {
	const isSignedIn = useSelector((state) => state.auth.isSignedIn);
	return (
		<Route
			{...rest}
			render={() =>
				isSignedIn ? children : <Redirect to={{ pathname: "/login" }} />
			}
		/>
	);
};

const Routes = () => (
	<Switch>
		<Route exact path={["/", "/login"]}>
			<Login />
		</Route>
		<Route path="/accountCreation">
			<AccountCreation />
		</Route>
		<Route path="/register">
			<Register />
		</Route>
		<AuthenticatedRoute path="/loading">
			<LoadingPage />
		</AuthenticatedRoute>
		<AuthenticatedRoute path="/dashboard">
			<Dashboard />
		</AuthenticatedRoute>
		<AuthenticatedRoute path="/chat">
			<Chat />
		</AuthenticatedRoute>
		<AuthenticatedRoute path="/survey">
			<Survey />
		</AuthenticatedRoute>
		<AuthenticatedRoute path="/voice">
			<Voice />
		</AuthenticatedRoute>
		<AuthenticatedRoute path="/toDoList">
			<ToDoList />
		</AuthenticatedRoute>
		<AuthenticatedRoute path="/settings">
			<Settings />
		</AuthenticatedRoute>
		<AuthenticatedRoute path="/mode">
			<ModeSelection />
		</AuthenticatedRoute>
		<AuthenticatedRoute path="/welcome">
			<Welcome />
		</AuthenticatedRoute>
	</Switch>
);

export default Routes;

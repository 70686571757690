import API from "../API";
import { sendError, updateState, clearState } from "./sharedActions";

export const updateRegistration = (payload) => {
	return {
		type: "UPDATE_REGISTRATION",
		name: "auth",
		payload,
	};
};

export const verifyRegistrationEmail = (email, uid) => async (dispatch) => {
	dispatch(updateRegistration({ email, verified: false, loading: true }));
	try {
		await API(`/auth/invite/verify`, "POST", { email, uid });
		return dispatch(updateRegistration({ verified: true }));
	} catch (err) {
		dispatch(updateRegistration({ error: true }));
		return dispatch(sendError(err, "auth"));
	}
};

export const setRegisteredUser = ({ email, uid }, firebaseApp) => async (
	dispatch,
	getState
) => {
	try {
		const idToken = await firebaseApp.auth().currentUser.getIdToken();
		const user = await API(`/auth/register`, "POST", { idToken, email, uid });
		dispatch(
			updateState("auth", {
				user,
				registration: {
					...getState().auth.registration,
					email,
					verified: true,
				},
			})
		);

		return onUserChange(
			firebaseApp.auth().currentUser,
			firebaseApp,
			true
		)(dispatch, getState);
	} catch (err) {
		return dispatch(sendError(err, "auth"));
	}
};

export const onUserChange = (
	firebaseUser,
	firebaseApp,
	forceRefresh = false
) => async (dispatch, getState) => {
	const { loading, init, registration } = getState().auth;
	const isLoading = loading || registration.loading;

	if (init && isLoading) {
		return Promise.resolve(false);
	}
	dispatch(updateState("auth", { init: true, loading: true }));

	if (firebaseUser) {
		// If the firebase user changed, then we need to reload the user data from the API
		const { uid } = firebaseUser;
		const idToken = await firebaseUser.getIdToken(forceRefresh);

		try {
			const { isAdmin, isSuperAdmin, invite, ...user } = await API(
				`/auth/verify`,
				"POST",
				{
					uid,
					idToken,
					email: firebaseUser.email,
				}
			);

			const getParams = new URLSearchParams(window.location.search);
			if (invite && !getParams.get("redirect")) {
				window.location = `/accountCreation?redirect=true`;
			} else {
				return dispatch(
					updateState("auth", {
						user,
						isSignedIn: true,
						accessMap: {
							isAdmin,
							isSuperAdmin,
						},
					})
				);
			}
		} catch (error) {
			return dispatch(clearState("auth"));
		}
	} else {
		return dispatch(
			updateState("auth", { user: {}, isSignedIn: false, loading: false })
		);
	}
};

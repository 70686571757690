import React, { useEffect, useState } from "react";
import { useMountEffect } from "../hooks/useMountEffect";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { showSnackbar } from "../actions/uiActions";
import API from "../API";
import { getContent } from "../shared";
import Loading from "../Components/Loading";
import TextInput from "../Components/TextInput";
import DropDown from "../Components/DropDown";
import Button from "../Components/Button";
import Headline from "../Components/Headline";
import Container from "../Components/FlexContainer";
import { parse, parseDate } from "chrono-node";
import { DateTime } from "luxon";

const AccountCreation = () => {
	const [content, setContent] = useState(null);
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [gender, setGender] = useState("");
	const [nickname, setNickname] = useState("");
	const [nicknameFocused, setNicknameFocused] = useState(false);
	const [dateOfBirth, setDateOfBirth] = useState("");
	const [zipCode, setZipCode] = useState("");
	const [valid, setValid] = useState(true);
	const [saving, setSaving] = useState(false);
	const dispatch = useDispatch();
	let history = useHistory();

	useMountEffect(() => {
		getContent(4).then(setContent);
	});

	useEffect(() => {
		if (lastName.length && !nicknameFocused) {
			setNickname(`${firstName} ${lastName}`);
		}
	}, [firstName, lastName, nicknameFocused]);

	const onSubmit = async () => {
		const fields = validOrNull({
			firstName,
			lastName,
			gender,
			nickname,
			dateOfBirth,
			zipCode,
		});

		if (saving || !fields) {
			setValid(false);
			dispatch(showSnackbar(`Invalid information given`, "warning"));
			setTimeout(() => setValid(true), 3000);
			return;
		}

		dispatch(showSnackbar("Saving...", "info"));
		setSaving(true);

		try {
			await API(`/auth/registerDetails`, "POST", {
				...fields,
				postalCode: fields.zipCode,
			});
			setValid(true);
			dispatch(showSnackbar("Success!", "success"));
			// On success -> redirect to dashboard
			history.push("/dashboard");
		} catch (error) {
			setValid(false);
			// Reset validity after 3 seconds in case they can't figure it out
			setTimeout(() => setValid(true), 3000);

			// Response will tell us which fields are invalid
			if (error.errors) {
				dispatch(showSnackbar(errorMessageReducer(error.errors)));
			} else {
				setSaving(false);
				throw error;
			}
		}
		setSaving(false);
	};

	if (!content) {
		return <Loading />;
	}

	return (
		<Container>
			<div style={{ width: "69%" }}>
				<Headline>{content.headline || "Create your Account"}</Headline>
				{content.statement ? <p>{content.statement}</p> : null}
				<TextInput
					label={content.firstNameLabel || "First Name"}
					value={firstName}
					onChange={(e) => setFirstName(e.target.value)}
					error={!valid && !validateName(firstName)}
					style={{ marginBottom: 15 }}
				/>
				<TextInput
					label={content.lastNameLabel || "Last Name"}
					value={lastName}
					onChange={(e) => setLastName(e.target.value)}
					error={!valid && !validateName(lastName)}
					style={{ marginBottom: 15 }}
				/>
				<TextInput
					label={content.nickname}
					value={nickname}
					onChange={(e) => setNickname(e.target.value)}
					onFocus={() => setNicknameFocused(true)}
					error={!valid && !validateNickname(nickname)}
					style={{ marginBottom: 15 }}
				/>
				<DropDown
					label="Gender"
					value={gender}
					onChange={(e) => setGender(e.target.value)}
					options={genderPickerList}
					style={{ marginBottom: 15 }}
				/>
				<TextInput
					label="Date of Birth"
					value={dateOfBirth}
					onChange={(e) => setDateOfBirth(e.target.value)}
					helperText={
						!valid || parse(dateOfBirth).length === 0
							? "Invalid Date. Please use M/D/YYYY."
							: null
					}
					error={!valid || !validateDob(dateOfBirth)}
					style={{ marginBottom: 15 }}
					onBlur={() => {
						const parsedDate = parseDate(dateOfBirth);
						if (parsedDate) {
							setDateOfBirth(DateTime.fromJSDate(parsedDate).toLocaleString());
						}
					}}
				/>
				<TextInput
					label={content["zip/postalCode"]}
					value={zipCode}
					onChange={(e) => setZipCode(e.target.value)}
					placeholder="Zip/Postal Code"
					error={!valid && !validateZipCode(zipCode)}
					style={{ marginBottom: 15 }}
				/>
				<Button
					onClick={onSubmit}
					disabled={saving || !valid}
					text={content.continueLabel || "Save"}
				/>
			</div>
		</Container>
	);
};

export default AccountCreation;

//-- Form Field values
// FIXME: CR 2020-Nov-07 - Don't hardcode this
const genderOptions = [
	"Female",
	"Gender Variant/Non-Conforming",
	"Male",
	"Other",
	"Prefer not to answer",
	"Removed by user",
	"Transgender Female",
	"Transgender Male",
	"Unknown",
	"Unsure",
];

const genderPickerList = genderOptions.map((value, index) => ({
	value,
	label: value,
}));

//-- Validation Helpers
const alwaysTrue = (..._) => true;

const validateName = (name) => name && name?.length > 2;
const validateGender = (gender) => genderOptions.includes(gender);
const validateNickname = alwaysTrue;
const validateDob = alwaysTrue;
const validateZipCode = (int) =>
	int ? !isNaN(int) && String(int).length >= 5 : true;

const validatorMap = {
	firstName: validateName,
	lastName: validateName,
	gender: validateGender,
	nickname: validateNickname,
	dateOfBirth: validateDob,
	zipCode: validateZipCode,
};

const formIsValid = (fieldMap) =>
	Object.entries(fieldMap).reduce(
		(isValid, [fieldName, fieldValue]) =>
			isValid && validatorMap[fieldName](fieldValue),
		true
	);

const validOrNull = (fields) => (formIsValid(fields) ? fields : null);

/**
 * @func errorMessageReducer - Transpose from a list of errors -> string of error messages
 * @param {{ field: String, message: String}[]} errorList
 * @returns {String}
 */
const errorMessageReducer = (errorList) =>
	errorList.reduce(
		(message, error) => `${message}\n${error.field}: ${error.message}`,
		""
	);

import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "./FlexContainer";

const Loading = () => {
	return (
		<Container>
			<CircularProgress size={75} />
		</Container>
	);
};
export default Loading;

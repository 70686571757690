import React, { useState } from "react";
import { useMountEffect } from "../hooks/useMountEffect";
import { useDispatch, useSelector } from "react-redux";
import hark from "hark";
import moment from "moment";
import { startVoice, voiceSubmit } from "../actions/voiceActions";
import { sendError } from "../actions/sharedActions";
import StatementView from "../Components/StatementView";
import SurveyView from "../Components/SurveyView";
import CheckboxView from "../Components/CheckboxView";
import Loading from "../Components/Loading";
import Button from "../Components/Button";
import Container from "../Components/FlexContainer";
import Headline from "../Components/Headline";
import Grid from "@material-ui/core/Grid";
import AvaTalking from "../images/AvaSmileBlob.svg";
import CheckInHeader from "../Components/CheckInHeader";
import { colors } from "../globalColors";
import { getContent } from "../shared";
import Video from "../Components/Video";

const Voice = () => {
	const voiceState = useSelector((state) => state.voiceReducer);
	const dispatch = useDispatch();
	const [showAva, setShowAva] = useState(false);
	const [userTalking, setUserTalking] = useState(false);
	const [content, setContent] = useState(null);
	const [loading, setLoading] = useState(false);
	let recorder;

	useMountEffect(() => {
		toggleLoading();
		dispatch(startVoice());
		getContent(7406).then(setContent);
		setShowAva(true);
	});

	const toggleLoading = (toggleAvaOn = false) => {
		setLoading(true);
		setTimeout(() => {
			setLoading(false);
			toggleAvaOn && setShowAva(true);
		}, 1000);
	};

	const startRecording = () => {
		navigator.mediaDevices
			.getUserMedia({ audio: true })
			.then((stream) => {
				recorder = new MediaRecorder(stream);

				recorder.addEventListener("dataavailable", (e) => {
					dispatch(voiceSubmit(e.data));
				});

				recorder.start();

				if (!userTalking) {
					setUserTalking(true);
				}

				const startedRecording = new Date();

				const speechEvents = hark(stream, {});
				speechEvents.on("stopped_speaking", () => {
					if (
						startedRecording &&
						Math.abs(moment().diff(startedRecording)) > 1000
					) {
						setUserTalking(false);
						recorder.stop();
						recorder.stream.getTracks().forEach((i) => i.stop());
						toggleLoading(true);
					}
				});
			})
			.catch((err) => dispatch(sendError(err.message, "voice")));
	};

	const VoiceView = () => (
		<div
			style={{
				backgroundColor: colors.chat.chatBackground,
				width: "100%",
				display: "flex",
				justifyContent: "center",
				padding: "20px 0px",
			}}
		>
			<div style={{ width: "69%" }}>
				<Grid container spacing={1} alignItems="center">
					<Grid item xs={8}>
						{loading && <Loading />}
						{showAva && !loading && (
							<>
								<Headline>{content.headlineAva}</Headline>
								<p>{content.helperTextAva}</p>
								<audio
									id="ava-audio"
									src={showAva ? voiceState.blobURL : null}
									autoPlay
									onEnded={() => {
										toggleLoading();
										setShowAva(false);
									}}
								/>
							</>
						)}
						{!showAva && !loading && (
							<>
								<Headline>{content.headlineParticipant}</Headline>
								<p>{content.helperTextParticipant}</p>
								<Video video={voiceState.video} />
								<Button
									text={!userTalking ? content.buttonText : "Listening..."}
									onClick={startRecording}
									style={{
										backgroundColor: userTalking
											? colors.activeRecordingButton
											: colors.primary,
									}}
								/>
							</>
						)}
					</Grid>
					<Grid item xs={4}>
						<img src={AvaTalking} alt="Ava" />
					</Grid>
				</Grid>
			</div>
		</div>
	);

	if (!content) {
		return <Loading />;
	}

	return (
		<Container>
			<CheckInHeader currentMode="Survey" />
			{voiceState.messageType === "statement" && (
				<StatementView
					question={voiceState.messages.join("<br>")}
					buttonText={voiceState.statementButton}
					shouldEndSession={Boolean(voiceState.shouldEndSession)}
					mode="voice"
					video={voiceState.video}
				/>
			)}
			{voiceState.messageType === "surveyMode" && (
				<SurveyView
					question={voiceState.messages.join("<br>")}
					answers={voiceState.answers}
					answerType="list"
					mode="voice"
					video={voiceState.video}
				/>
			)}
			{voiceState.messageType === "checkboxes" && (
				<CheckboxView
					question={voiceState.messages.join("<br>")}
					answers={voiceState.checkboxes}
					mode="voice"
					video={voiceState.video}
				/>
			)}
			{voiceState.messageType === "question" && <VoiceView />}
			<p style={{ paddingTop: 10 }}>Message: {voiceState.currentMessageName}</p>
		</Container>
	);
};

export default Voice;

/**
 * @module Register - Handles user email confirmation + account creation/registration
 */
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
	setRegisteredUser,
	updateRegistration,
	verifyRegistrationEmail,
} from "../actions/authActions";
import { clearState, sendError } from "../actions/sharedActions";
import { firebaseApp } from "../firebase";
import { AccessDenied } from "./Login";
import Loading from "../Components/Loading";
import TextInput from "../Components/TextInput";
import Button from "../Components/Button";
import Container from "../Components/FlexContainer";
import Headline from "../Components/Headline";
import API from "../API";

//FIXME 12-11-2020: Getting warning "Cannot update a component (`ConfirmEmailForm`) while rendering a different component (`Register`)"
const Register = () => {
	const { error, registration } = useSelector((state) => state.auth);
	const dispatch = useDispatch();

	if (error) {
		return (
			<AccessDenied
				statement={error}
				onContinueClick={dispatch(clearState("auth"))}
			/>
		);
	}

	return (
		<Container>
			<div style={{ width: "69%" }}>
				{!registration.verified ? <ConfirmEmailForm /> : <RegistrationForm />}
			</div>
		</Container>
	);
};

export default Register;

const ConfirmEmailForm = () => {
	const [email, setEmail] = useState("");
	const registration = useSelector((state) => state.auth.registration);
	const dispatch = useDispatch();
	const confirmEmail = () => {
		if (email) {
			const { uid } = getUrlParams();
			dispatch(verifyRegistrationEmail(email, uid));
		}
	};

	if (registration.loading) {
		return <Loading />;
	}

	return (
		<>
			<Headline>Confirm your Email</Headline>
			<TextInput
				label="Email Confirmation"
				value={email}
				onChange={(e) => setEmail(e.target.value)}
				autoFocus
			/>

			<Button disabled={!email} onClick={confirmEmail} text="Continue" />
		</>
	);
};

const getUrlParams = () =>
	Object.fromEntries(new URL(window.location.href).searchParams.entries());

const defaultRegistrationContent = {
	statement: "Please register",
	usernameLabel: "Email",
	usernamePlaceholder: "myemail@example.com",
	passwordLabel: "Password",
	continueLabel: "Continue",
};

const RegistrationForm = ({ content = defaultRegistrationContent }) => {
	const registration = useSelector((state) => state.auth.registration);
	const dispatch = useDispatch();
	const [email, setEmail] = useState(registration.email || "");
	const [password, setPassword] = useState("");
	const history = useHistory();

	const signInWithEmailPassword = async () => {
		try {
			dispatch(updateRegistration({ loading: true }));
			const getParams = new URLSearchParams(window.location.search);
			const { accountExists, error } = await API(
				`/auth/registerPassword`,
				"POST",
				{
					email,
					password,
					uid: getParams.get("uid"),
				}
			);

			if (accountExists) {
				dispatch(
					sendError(
						{
							message:
								"Invitation already accepted. Redirecting you to sign in...",
						},
						"auth"
					)
				);
				history.push("/login?redirect=true");
			} else if (!error) {
				await firebaseApp.auth().signInWithEmailAndPassword(email, password);
				const idToken = await firebaseApp.auth().currentUser.getIdToken();
				await dispatch(
					setRegisteredUser(
						{ idToken, email, uid: firebaseApp.auth().currentUser.uid },
						firebaseApp
					)
				);
				history.push("/accountCreation");
			}
		} catch (error) {
			dispatch(updateRegistration({ error: true }));
			return dispatch(sendError(error, "auth"));
		}
	};

	return (
		<>
			<Headline>{content.statement}</Headline>
			<TextInput
				label={content.usernameLabel}
				value={email}
				onChange={(e) => setEmail(e.target.value)}
				placeholder={content.usernamePlaceholder}
				style={{ marginBottom: 15 }}
			/>
			<TextInput
				type="password"
				label={content.passwordLabel}
				value={password}
				onChange={(e) => setPassword(e.target.value)}
				autoFocus
			/>
			<Button onClick={signInWithEmailPassword} text={content.continueLabel} />
		</>
	);
};

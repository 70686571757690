import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import MaterialButton from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import Button from "../Components/Button";
import { colors } from "../globalColors";
import { SmallHeadline } from "./Headline";

export const Border = styled.div`
	border: 1px solid ${colors.dashboardTileBorder};
	border-radius: 15px;
	width: 100%;
	background-color: ${colors.mainBackground};
`;

const StyledCard = styled(Card)`
	box-shadow: none;
	border-radius: 15px;
	height: 330px;
	display: flex;
	flex-direction: column;
	align-content: space-between;
`;

const CheckInTile = ({ headline, body, button, buttonLink, image }) => (
	<Border style={{ height: "100%" }}>
		<Grid container style={{ padding: 25 }} justify="center">
			<Grid item xs={12}>
				<SmallHeadline>{headline}</SmallHeadline>
			</Grid>
			<Grid item xs={9}>
				<p>{body}</p>
				<Link to={buttonLink}>
					<Button text={button} />
				</Link>
			</Grid>
			<Grid item xs={3} style={{ alignSelf: "center" }}>
				<img src={image} alt="Check In" style={{ width: "100%" }} />
			</Grid>
		</Grid>
	</Border>
);

const CardTile = ({ title, image, body, showActions = true, link }) => {
	const [clicked, setClicked] = useState(null);
	return (
		<StyledCard>
			<CardMedia
				style={{
					objectFit: "scale-down",
					cursor: "pointer",
					width: "95%",
					margin: "auto",
				}}
				component="img"
				alt={title}
				height="25%"
				image={image}
				title={title}
				onClick={() => {
					window.open(link, "_blank");
				}}
			/>
			<CardContent
				style={{ justifyContent: "center", height: "calc(50% - 16px)" }}
			>
				<a href={link} target="_blank" rel="noreferrer">
					<h3
						style={{
							color: colors.primary,
							fontSize: 16,
							fontWeight: 500,
						}}
					>
						{title}
					</h3>
				</a>
				<a href={link} target="_blank" rel="noreferrer">
					<p style={{ fontSize: 14, paddingTop: 10 }}>{body}</p>
				</a>
			</CardContent>
			{showActions && (
				<CardActions
					style={{
						display: "flex",
						justifyContent: "space-between",
						height: "calc(15% - 16px)",
					}}
				>
					<div>
						<a href={link} target="_blank" rel="noreferrer">
							<MaterialButton color="primary" style={{ textTransform: "none" }}>
								Read More
							</MaterialButton>
						</a>
					</div>
					<div style={{ padding: 6 }}>
						<IconButton onClick={() => setClicked(true)} size="small">
							<ThumbUpIcon
								color={!clicked ? "primary" : "secondary"}
								fontSize="inherit"
							/>
						</IconButton>
						<IconButton onClick={() => setClicked(false)} size="small">
							<ThumbDownIcon
								color={clicked === null || clicked ? "primary" : "secondary"}
								fontSize="inherit"
							/>
						</IconButton>
					</div>
				</CardActions>
			)}
		</StyledCard>
	);
};

const ListTile = ({
	title,
	children,
	titlePadding = 15,
	titleLeftMargin = 20,
}) => {
	return (
		<Border style={{ height: "100%" }}>
			<Grid
				container
				justify="center"
				spacing={1}
				direction="column"
				style={{ padding: 20 }}
			>
				<Grid item xs={12}>
					<SmallHeadline
						style={{ marginLeft: titleLeftMargin, paddingBottom: titlePadding }}
					>
						{title}
					</SmallHeadline>
				</Grid>
				<Grid item xs={12}>
					{children}
				</Grid>
			</Grid>
		</Border>
	);
};

export { CheckInTile, CardTile, ListTile };

import ReactPlayerLoader from "@brightcove/react-player-loader";
import React from "react";

const Video = ({ video, parentStyle, childStyle }) => {
	if (!video) {
		return null;
	}

	return (
		<div
			style={{
				// justifyContent: "center",
				display: "flex",
				marginTop: 20,
				marginBottom: 20,
				...parentStyle,
			}}
		>
			<div style={{ maxWidth: 600, width: "100%", ...childStyle }}>
				<ReactPlayerLoader
					accountId="6184453724001"
					playerId="50FA4AtN8"
					videoId={`ref:${video}`}
					embedOptions={{ responsive: true }}
				/>
			</div>
		</div>
	);
};

export default Video;

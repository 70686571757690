import { updateState } from "./sharedActions";

export const showSnackbar = (message, alertType = "error") => (dispatch) => {
	return dispatch(
		updateState("ui", {
			snackbarOpen: true,
			snackbarMessage: message,
			alertType,
		})
	);
};

export const closeSnackbar = () => (dispatch) => {
	return dispatch(
		updateState("ui", {
			snackbarOpen: false,
			snackbarMessage: "",
		})
	);
};

export const showModal = (messageData, mode, messageType) => (dispatch) => {
	return dispatch(
		updateState("ui", {
			mode,
			messageType,
			modalOpen: true,
			...messageData,
		})
	);
};

export const closeModal = () => (dispatch) => {
	return dispatch(updateState("ui", { modalOpen: false }));
};

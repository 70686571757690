import React, { useEffect, useState } from "react";
import { useMountEffect } from "../hooks/useMountEffect";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getContent } from "../shared";
import { firebaseApp } from "../firebase";
import { sendError } from "../actions/sharedActions";
import Loading from "../Components/Loading";
import TextInput from "../Components/TextInput";
import Button from "../Components/Button";
import Container from "../Components/FlexContainer";
import Headline from "../Components/Headline";
import API from "../API";

// export const signInWithGoogle = () =>
//     firebaseApp.auth().signInWithPopup(googleProvider)
//
// export const signInWithApple = () =>
//     firebaseApp.auth().signInWithPopup(appleProvider)

export const signInWithEmailPassword = ({ email, password }) =>
	firebaseApp.auth().signInWithEmailAndPassword(email, password);

/**
 * @class Component - Provides form for users to enter their email/pw or sign-in with a third-party
 */

export const Login = () => {
	const dispatch = useDispatch();
	const authState = useSelector((state) => state.auth);
	const { init, loading, isSignedIn } = authState;
	const [content, setContent] = useState(null);
	let history = useHistory();
	const getParams = new URLSearchParams(window.location.search);
	const redirect = getParams.get("redirect");

	useMountEffect(() => {
		if (!isSignedIn || getParams.get("redirect")) {
			getContent(216).then(setContent);
		}
	});

	const handleSubmit = async ({ email, password }) => {
		try {
			await firebaseApp.auth().signInWithEmailAndPassword(email, password);

			const firebaseUser = firebaseApp.auth().currentUser;
			if (!firebaseUser) {
				throw new Error({ message: "Sign in failed" });
			}
			const { uid } = firebaseUser;
			const idToken = await firebaseUser.getIdToken();

			const { invite } = await API(`/auth/verify`, "POST", {
				uid,
				idToken,
				email: firebaseUser.email,
			});

			if (invite) {
				await API(`/auth/register`, "POST", {
					idToken,
					email,
					uid,
				});
				history.push("/accountCreation?redirect=true");
			} else {
				history.push("/dashboard");
			}
		} catch (error) {
			dispatch(sendError(error, "auth"));
		}
	};

	useEffect(() => {
		if (isSignedIn && !redirect) {
			history.push("/dashboard");
		}
	}, [history, isSignedIn, redirect]);

	if (!content) {
		return <Loading />;
	}
	if ((loading || !init) && !getParams.get("redirect")) {
		return <Loading />;
	}

	return (
		<Container>
			<div style={{ width: "69%" }}>
				<LoginForm onSubmit={handleSubmit} content={content} />
			</div>
		</Container>
	);
};
export default Login;

//-- Presentational components for Auth workflow

const defaultLoginContent = {
	statement: "Please sign in",
	usernameLabel: "Email address",
	usernamePlaceholder: "myemail@example.com",
	passwordLabel: "Password",
	continueLabel: "Continue",
};

export const LoginForm = ({
	content = defaultLoginContent,
	onSubmit,
	// googleClick,
	// appleClick,
}) => {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	return (
		<>
			<Headline>{content.statement}</Headline>
			<TextInput
				label={content.usernameLabel}
				value={email}
				onChange={(e) => setEmail(e.target.value)}
				placeholder={content.usernamePlaceholder}
				required
				style={{ marginBottom: 15 }}
			/>
			<TextInput
				type="password"
				label={content.passwordLabel}
				value={password}
				onChange={(e) => setPassword(e.target.value)}
				required
			/>
			<Button
				onClick={() => onSubmit({ email, password })}
				text={content.continueLabel}
			/>
		</>
	);
};

export const validatePassword = (pw) => {
	if (!pw) {
		return false;
	}
	const password = String(pw).trim();
	return password.length > 6;
};

export const AccessDenied = ({ onContinueClick }) => {
	const [content, setContent] = useState(null);

	useMountEffect(() => {
		getContent(1).then(setContent);
	});

	if (!content) {
		return <Loading />;
	}

	return (
		<>
			<Headline>{content?.statement}</Headline>
			<Button onClick={onContinueClick} text={content?.continueLabel} />
		</>
	);
};

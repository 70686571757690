import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import RefreshIcon from "@material-ui/icons/Refresh";
import HomeIcon from "@material-ui/icons/Home";
import SettingsIcon from "@material-ui/icons/Settings";
import Routes from "../Routes";
import { firebaseApp } from "../firebase";
import Grid from "@material-ui/core/Grid";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { getContent } from "../shared";
import API from "../API";
import { clearState, sendError } from "../actions/sharedActions";
import { useHistory } from "react-router-dom";
import { startChat } from "../actions/chatActions";
import { startVoice } from "../actions/voiceActions";
import { startSurvey } from "../actions/surveyActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import AsyncAutocomplete from "react-select/async";

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	menuButton: {
		color: "#6f6f6f",
	},
	title: {
		flexGrow: 1,
		textAlign: "center",
	},
}));

const Header = () => {
	const classes = useStyles();
	const isSignedIn = useSelector((state) => state.auth.isSignedIn);
	const [anchorEl, setAnchorEl] = useState();
	const [resetButtonContent, setResetButtonContent] = useState();
	const [specificMessageDialogOpen, setSpecificMessageDialogOpen] = useState(
		false
	);
	const [specificMessage, setSpecificMessage] = useState();

	let history = useHistory();
	const dispatch = useDispatch();

	useEffect(() => {
		(async () => {
			setResetButtonContent(await getContent(7498));
		})();
	}, []);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const loadOptions = async (search) => {
		const { results } = await API(`/content/messageNameSearch`, "POST", {
			query: search,
		});
		return results.map((message) => {
			return {
				label: message.name,
				value: message.id,
				shapeID: message.shapeID,
				canvasID: message.canvasID,
			};
		});
	};

	const resetUserData = async (resetPoint) => {
		try {
			if (resetPoint === 1) {
				// if (userMode) {
				// 	dispatch(updateState("auth", { userMode: null }));
				// 	dispatch(clearState(userMode === "toDoList" ? "survey" : userMode));
				// }
				const res = await API(`/user/userData`, "DELETE", { mode: true });
				if (res.redirect) {
					history.push(res.redirect);
				} else if (res.success) {
					history.push("/welcome");
				}
			} else {
				const { mode } = await API(`/user/resetPoint`, "POST", {
					resetPoint: 0,
				});
				let shape;
				if (resetPoint === 2) {
					shape = {
						canvasID: 8,
						shapeID: 4,
					};
				} else if (resetPoint === 3) {
					shape = {
						canvasID: 5,
						shapeID: 18,
					};
				} else {
					shape = {
						canvasID: 13,
						shapeID: 3,
					};
				}

				if (mode === "chat") {
					dispatch(clearState("chat"));
					dispatch(startChat(shape));
				} else if (mode === "voice") {
					dispatch(clearState("voice"));
					dispatch(startVoice(shape));
				} else if (mode === "survey") {
					dispatch(clearState("survey"));
					dispatch(startSurvey("Survey", shape));
				} else if (mode === "toDoList") {
					dispatch(clearState("survey"));
					dispatch(startSurvey("To Do List", shape));
				}
				history.push(mode ? `/${mode}` : `/mode`);
			}
		} catch (err) {
			dispatch(sendError(err, "ui"));
		}
		setAnchorEl(null);
	};

	return (
		<div className={classes.root}>
			<AppBar color="transparent" position="static" elevation={0}>
				<Toolbar>
					<Grid container justify="space-between" alignItems="center">
						<Grid item xs={2}>
							<Link to={isSignedIn ? "/dashboard" : "/"}>
								<IconButton
									edge="start"
									className={classes.menuButton}
									color="inherit"
									aria-label="menu"
								>
									<HomeIcon />
								</IconButton>
							</Link>
						</Grid>

						<Grid item xs={8}>
							<Typography variant="h6" className={classes.title}>
								engagement<strong style={{ color: "#009ee2" }}>Rx</strong>
							</Typography>
						</Grid>

						<Grid item xs={2} style={{ textAlign: "right" }}>
							{isSignedIn && (
								<>
									{resetButtonContent && (
										<IconButton
											onClick={handleClick}
											className={classes.menuButton}
											color="inherit"
											aria-label="menu"
										>
											<RefreshIcon />
										</IconButton>
									)}
									<Link to="/settings">
										<IconButton
											className={classes.menuButton}
											color="inherit"
											aria-label="menu"
										>
											<SettingsIcon />
										</IconButton>
									</Link>
								</>
							)}
							{isSignedIn ? (
								<IconButton
									onClick={async () => {
										await firebaseApp.auth().signOut();
										history.replace(`/login?redirect=true`);
									}}
								>
									<ExitToAppIcon />
								</IconButton>
							) : (
								<Link to="/login">
									<IconButton edge="end">
										<AccountCircleIcon />
									</IconButton>
								</Link>
							)}
						</Grid>
					</Grid>
				</Toolbar>
			</AppBar>
			{resetButtonContent && (
				<Menu
					id="simple-menu"
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleClose}
				>
					<MenuItem
						onClick={() => {
							resetUserData(1);
						}}
					>
						{resetButtonContent.firstVisitModeChoice}
					</MenuItem>
					<MenuItem
						onClick={() => {
							resetUserData(2);
						}}
					>
						{resetButtonContent.carePathWelcome}
					</MenuItem>
					<MenuItem
						onClick={() => {
							resetUserData(3);
						}}
					>
						{resetButtonContent.firstCheckInIntroduction}
					</MenuItem>
					<MenuItem
						onClick={() => {
							resetUserData(4);
						}}
					>
						{resetButtonContent.checkInIntroduction}
					</MenuItem>
					<MenuItem
						onClick={async () => {
							setSpecificMessageDialogOpen(true);
						}}
					>
						{resetButtonContent?.specificMessage || "Specific Message Name"}
					</MenuItem>
				</Menu>
			)}
			<Routes />
			<Dialog
				open={specificMessageDialogOpen}
				onClose={() => {
					setSpecificMessageDialogOpen(false);
					setSpecificMessage(null);
				}}
				aria-labelledby="form-dialog-title"
				fullWidth
				maxWidth="sm"
			>
				<DialogTitle id="form-dialog-title">Jump to Message</DialogTitle>
				<DialogContent>
					<DialogContentText>Enter message name:</DialogContentText>
					<AsyncAutocomplete
						onChange={(val) => {
							setSpecificMessage(val);
						}}
						menuPosition={"fixed"}
						value={specificMessage}
						menuPortalTarget={
							document.getElementsByClassName("MuiDialog-container")[0]
						}
						autoFocus={true}
						style={{
							menu: (provided) => ({
								...provided,
								zIndex: 9999,
							}),
						}}
						cacheOptions
						loadOptions={loadOptions}
						noOptionsMessage={({ inputValue }) =>
							inputValue
								? "No matching messages"
								: "Start typing a message name..."
						}
					/>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => {
							setSpecificMessageDialogOpen(false);
							setSpecificMessage(null);
						}}
						color="primary"
					>
						Cancel
					</Button>
					<Button
						onClick={async () => {
							setSpecificMessage(null);
							setSpecificMessageDialogOpen(false);
							const { mode } = await API(`/user/resetPoint`, "POST", {
								resetPoint: 0,
							});
							if (mode === "chat") {
								dispatch(clearState("chat"));
								dispatch(
									startChat({
										canvasID: specificMessage.canvasID,
										shapeID: specificMessage.shapeID,
									})
								);
							} else if (mode === "voice") {
								dispatch(clearState("voice"));
								dispatch(
									startVoice({
										canvasID: specificMessage.canvasID,
										shapeID: specificMessage.shapeID,
									})
								);
							} else if (mode === "survey") {
								dispatch(clearState("survey"));
								dispatch(
									startSurvey("Survey", {
										canvasID: specificMessage.canvasID,
										shapeID: specificMessage.shapeID,
									})
								);
							} else if (mode === "toDoList") {
								dispatch(clearState("survey"));
								dispatch(
									startSurvey("To Do List", {
										canvasID: specificMessage.canvasID,
										shapeID: specificMessage.shapeID,
									})
								);
							}
						}}
						color="primary"
						disabled={!specificMessage}
					>
						Go
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};
export default Header;

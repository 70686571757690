import camel from "camelcase";
import API from "./API";

export const getContent = (
	messageID,
	includeFormatting = false,
	predicate = camelKeys
) =>
	API(`/content/${messageID}`, "POST", { includeFormatting }).then(
		predicate || identity
	);

const identity = (any) => any;

export const camelKeys = (obj) =>
	Object.entries(obj).reduce(
		(accum, [key, value]) => ({ ...accum, [camel(key)]: value }),
		{}
	);

import parse from "html-react-parser";

/**
 * @function HTMLText - Parses HTML provided from the server to display formatted in browser
 * @param {string} html - HTML to parse
 * @return {object} React Element
 */
const HTMLText = (html) => {
	html = html.replace(/<p><p>/g, "<p>");
	html = html.replace(/<\/p><\/p>/g, "</p>");
	return parse(html);
};

/**
 * @function noHTML - Removes HTML tags from strings
 * @param {string} html - HTML to parse
 * @return {string} html - String with no HTML tags
 */
const noHTML = (html) => {
	return html.replace(/<.*?>|<\/.*?>/g, "");
};

export { HTMLText, noHTML };

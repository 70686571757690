import React from "react";
import Grid from "@material-ui/core/Grid";
import InfoIcon from "@material-ui/icons/Info";
import { colors } from "../globalColors";
import { ListTile } from "./DashboardTiles";
import Loading from "./Loading";

const Tips = ({ list }) => {
	if (!list[1]) {
		return <Loading />;
	}
	return (
		<ListTile title="Tips">
			{["1", "2", "3"].map((number) => (
				<Grid
					container
					key={`tip-${number}`}
					style={{ marginBottom: 15 }}
					justify="space-between"
				>
					<Grid item xs={1}>
						<InfoIcon style={{ color: colors.lemon, fontSize: "1.8rem" }} />
					</Grid>
					<Grid item xs={11}>
						<h4 style={{ color: colors.primary, fontWeight: 600 }}>
							{list[number]?.Headline}
						</h4>
						<p style={{ fontSize: 14 }}>{list[number]?.Tip}</p>
					</Grid>
				</Grid>
			))}
		</ListTile>
	);
};

export default Tips;

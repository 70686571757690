import { showSnackbar } from "./uiActions";

export const sendError = (err, reducer) => async (dispatch) => {
	console.error(err);
	dispatch(showSnackbar(err.response?.data.message || err.message));
	dispatch(updateState(reducer, { error: true }));
};

// Use these 3 in a dispatch for a little less boilerplate code
// ex: dispatch(updateState("chat", { loading: true }))
export const updateState = (reducerName, payload) => {
	return {
		type: "UPDATE_STATE",
		name: reducerName,
		payload,
	};
};

export const overrideState = (reducerName, payload) => {
	return {
		type: "OVERRIDE_STATE",
		name: reducerName,
		payload,
	};
};

export const clearState = (reducerName) => {
	return {
		type: "CLEAR_STATE",
		name: reducerName,
	};
};

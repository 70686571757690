import React, { useState } from "react";
import { useMountEffect } from "../hooks/useMountEffect";
import FormLabel from "@material-ui/core/FormLabel";
import camelcase from "camelcase";
import API from "../API";
import Loading from "../Components/Loading";
import TextInput from "../Components/TextInput";
import DropDown from "../Components/DropDown";
import Button, { LoadingStyledButton } from "../Components/Button";
import Checkboxes from "../Components/Checkboxes";
import Container from "../Components/FlexContainer";
import Headline from "../Components/Headline";

const Settings = () => {
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState({
		sms: false,
		email: false,
	});

	useMountEffect(() => {
		(async () => {
			const userData = await API(`/reminder/reminderSetting`, "GET");
			const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
			timezone ? setData({ ...userData, timezone }) : setData(userData);
		})();
	});

	if (!data) {
		return <Loading />;
	}

	const handleSubmit = async () => {
		await API(`/reminder/reminderSetting`, "PATCH", data);
	};

	const handleClick = async (type) => {
		if (loading.type) {
			return Promise.resolve(true);
		}
		setLoading({ ...loading, [type]: true });
		await API(`/reminder/${type}`, "GET");
		setLoading({ ...loading, [type]: false });
	};

	const frequencyOptions = [
		{ label: "Daily", value: 1 },
		{ label: "Every Other Day", value: 2 },
		{ label: "Weekly", value: 7 },
		{ label: "Off", value: null },
	];
	const typeOptions = [
		{ label: "Email", checked: Boolean(data.email) },
		{ label: "Push Notification", checked: Boolean(data.pushNotification) },
		{ label: "SMS", checked: Boolean(data.sms) },
	];
	const timezoneOptions = [
		{ label: "Eastern", value: "America/New_York" },
		{ label: "Central", value: "America/Chicago" },
		{ label: "Mountain", value: "America/Denver" },
		{ label: "Pacific", value: "America/Los_Angeles" },
	];

	return (
		<Container>
			<div style={{ width: "69%" }}>
				<Headline>Reminder Settings</Headline>
				<DropDown
					label="Frequency"
					value={data.frequency}
					onChange={(e) =>
						setData((prevState) => {
							return { ...prevState, frequency: e.target.value };
						})
					}
					options={frequencyOptions}
					style={{ marginBottom: 35 }}
				/>
				<FormLabel component="legend" style={{ marginBottom: 5 }}>
					Reminder Type
				</FormLabel>
				<Checkboxes
					options={typeOptions}
					onChange={(e) => {
						setData((prevState) => {
							return {
								...prevState,
								[camelcase(e.target.getAttribute("name"))]: e.target.checked,
							};
						});
					}}
				/>
				{data.sms && (
					<TextInput
						type="tel"
						label="Phone Number"
						onChange={(e) => {
							e.persist();
							setData((prevState) => {
								return {
									...prevState,
									phoneNumber: e.target.value,
								};
							});
						}}
						value={data.phoneNumber || ""}
						required
					/>
				)}
				<FormLabel
					component="legend"
					style={{ marginBottom: 5, marginTop: 30 }}
				>
					Reminder Time
				</FormLabel>
				<TextInput
					type="time"
					label="Time"
					onChange={(e) => {
						e.persist();
						setData((prevState) => {
							return { ...prevState, sendTime: e.target.value };
						});
					}}
					value={data.sendTime}
					required
					style={{ marginBottom: 15 }}
				/>
				{!Intl.DateTimeFormat().resolvedOptions().timeZone && (
					<DropDown
						value={data.timezone}
						onChange={(e) =>
							setData((prevState) => {
								return { ...prevState, timezone: e.target.value };
							})
						}
						options={timezoneOptions}
					/>
				)}
				<Button onClick={handleSubmit} text="Save" />
				{Boolean(data.sms) ? (
					<LoadingStyledButton
						loading={loading.sms}
						onClick={() => handleClick("sms")}
						text="Send SMS"
						style={{ margin: "30px 7.5px 0 15px" }}
					/>
				) : null}
				<LoadingStyledButton
					loading={loading.email}
					onClick={() => handleClick("email")}
					text="Send Email"
					style={{ margin: "30px 7.5px 0 7.5px" }}
				/>
			</div>
		</Container>
	);
};

export default Settings;

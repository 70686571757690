import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { chatSubmit } from "../actions/chatActions";
import { surveySubmit } from "../actions/surveyActions";
import { voiceSubmit } from "../actions/voiceActions";
import { HTMLText } from "./HTMLParser";
import Button from "./Button";
import Checkboxes from "./Checkboxes";
import Headline from "./Headline";
import Video from "./Video";

/**
 * @component CheckboxView - Used for checkbox messages types in a modal and in survey mode for checkboxes
 */

const CheckboxView = ({ question, answers, mode, video }) => {
	const [checkboxStatus, setCheckboxStatus] = useState({});
	const dispatch = useDispatch();

	const handleSubmit = () => {
		const userResponse = Object.keys(checkboxStatus).filter(
			(option) => checkboxStatus[option]
		);
		if (mode === "chat") {
			dispatch(chatSubmit(userResponse));
		} else if (mode === "voice") {
			dispatch(voiceSubmit(userResponse));
		} else {
			dispatch(surveySubmit(userResponse, mode));
		}

		setCheckboxStatus({});
	};

	return (
		<div style={{ width: "69%", paddingTop: 15 }}>
			<Headline>{HTMLText(question)}</Headline>
			<Video video={video} />
			<Checkboxes
				options={answers}
				onChange={(e) => {
					setCheckboxStatus({
						...checkboxStatus,
						[e.target.getAttribute("name")]: e.target.checked,
					});
				}}
			/>
			<Button text="Submit" onClick={handleSubmit} />
		</div>
	);
};

export default CheckboxView;

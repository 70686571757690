import React from "react";
import styled from "styled-components";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { colors } from "../globalColors";

const StyledSelect = styled(Select)`
	${({ theme }) => `
    width: 100%;
    background-color: ${colors.inputs.inputBackground};
    border-bottom: 1px solid ${theme.palette.primary.main};
    padding: 10px;
    &.MuiInput-underline:before {
      border-bottom: 1px solid ${theme.palette.primary.main};
    }
    &:hover {
      border-bottom: 1px solid ${theme.palette.primary.main};
    }
    &:focus {
      background-color: ${colors.inputs.inputBackground};
    }
    &:hover:not(.Mui-disabled):before {
      border-bottom: 1px solid ${theme.palette.primary.main};
    }
    .MuiSelect-select:focus {
      background-color: transparent;
    }
`}
`;

const DropDown = ({
	label,
	options,
	optionLabel = "label",
	optionValue = "value",
	optionKey,
	chatMode = false,
	...props
}) => (
	<>
		<InputLabel id="demo-simple-select-label">{label}</InputLabel>
		<StyledSelect
			{...props}
			style={
				chatMode
					? {
							backgroundColor: "white",
							border: `1px solid ${colors.primary}`,
							borderRadius: 5,
					  }
					: {}
			}
		>
			{options.map((option, index) => (
				<MenuItem
					key={option[optionKey] || `${option[optionValue]}-${index}`}
					value={option[optionValue]}
				>
					{option[optionLabel]}
				</MenuItem>
			))}
		</StyledSelect>
	</>
);

export default DropDown;

import React from "react";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import { colors } from "../globalColors";
import { ListTile } from "./DashboardTiles";
import Loading from "./Loading";

const ToDos = ({ list }) => {
	if (!list[1]) {
		return <Loading />;
	}
	return (
		<ListTile title={list.content["To Do List Card Headline"]}>
			{["1", "2", "3"].map((number) => (
				<Grid
					container
					key={`todo-${number}`}
					style={{ marginBottom: 15 }}
					justify="space-between"
					alignItems={list[number].Image ? "center" : "flex-start"}
				>
					<Grid item xs={1}>
						{list[number].Image && (
							<img src={list[number].Image} alt={list[number]?.Title} />
						)}
						{!list[number].Image && (
							<IconButton
								style={{
									backgroundColor: colors.accent,
									fontSize: "1.1rem",
									padding: 7,
								}}
							>
								<AssignmentTurnedInIcon fontSize="inherit" />
							</IconButton>
						)}
					</Grid>
					<Grid item xs={11} style={{ paddingLeft: 10 }}>
						<h4 style={{ color: colors.primary, fontWeight: 600 }}>
							{list[number]?.Title}
						</h4>
						<p style={{ fontSize: 14 }}>{list[number]?.Details}</p>
					</Grid>
				</Grid>
			))}
		</ListTile>
	);
};

export default ToDos;

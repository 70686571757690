import API from "../API";
import { sendError, updateState } from "./sharedActions";

const getMessageName = () => async (dispatch) => {
	const { name } = await API(`/user/currentMessageName`, "GET");
	return dispatch(updateState("survey", { currentMessageName: name }));
};

export const startSurvey = (mode = "Survey", startShapeID) => async (
	dispatch
) => {
	try {
		let res = await API("/survey", "POST", {
			start: true,
			includeFormatting: true,
			mode,
			startShapeID,
		});
		dispatch(getMessageName());
		return dispatch(updateState("survey", { ...res }));
	} catch (err) {
		return dispatch(sendError(err, "survey"));
	}
};

export const surveySubmit = (answer, mode = "Survey") => async (dispatch) => {
	try {
		let res = await API("/survey", "POST", {
			message: answer,
			includeFormatting: true,
			mode,
		});
		dispatch(getMessageName());
		return dispatch(updateState("survey", { ...res }));
	} catch (err) {
		return dispatch(sendError(err, "survey"));
	}
};

import React, { useState } from "react";
import styled from "styled-components";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { colors } from "../globalColors";

const StyledRadioButtons = styled(RadioGroup)`
	${({ theme }) => `
	[class^="PrivateRadioButtonIcon-root-"] {
		display: none;
	}
	.MuiRadio-root {
		display: none;
	}
	label {
		display: block;
		padding: 20px;
		border: 1px solid ${colors.inputs.radioBorderColor};
		border-radius: 5px;
		margin-bottom: 10px;
		margin-left: 0px;
		cursor: pointer;
	}

`}
`;

const StyledFormControlLabel = styled(FormControlLabel)`
	${({ theme }) => `
	&:hover, &:focus {
		background-color: rgba(0,0,0,.05);
	}
`}
`;

const RadioButtons = ({
	options,
	optionKey,
	optionValue = "value",
	optionLabel = "label",
	chatMode = false,
	...props
}) => {
	const [selected, setSelected] = useState(null);

	return (
		<>
			<StyledRadioButtons {...props}>
				{options.map((option, index) => {
					let style = {};

					if (selected === option[optionKey]) {
						style = {
							...style,
							borderColor: colors.primary,
							backgroundColor: colors.inputs.inputBackground,
						};
					}
					if (chatMode) {
						style.paddingTop = 5;
						style.paddingBottom = 5;
						style.fontSize = "18px !important";
						style.color = colors.chat.avaMessageFontColor;
						style.borderColor = colors.chat.avaMessageFontColor;
						style.marginRight = 60;
					}

					return (
						<StyledFormControlLabel
							key={option[optionKey] || `${option[optionValue]}-${index}`}
							value={option[optionValue]}
							control={
								<Radio onClick={(e) => setSelected(option[optionKey])} />
							}
							label={option[optionLabel]}
							style={style}
						/>
					);
				})}
			</StyledRadioButtons>
		</>
	);
};

export default RadioButtons;

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { chatSubmit } from "../actions/chatActions";
import { surveySubmit } from "../actions/surveyActions";
import { voiceSubmit } from "../actions/voiceActions";
import { HTMLText } from "./HTMLParser";
import Button from "./Button";
import TextInput from "./TextInput";
import RadioButtons from "./RadioButtons";
import DropDown from "./DropDown";
import Slider from "./Slider";
import Headline from "./Headline";
import Video from "./Video";

/**
 * @component SurveyView - Used for forced survey mode message types and survey mode
 */

//FIXME: Need to set up Slider in backend then hook up here

const SurveyView = ({
	question,
	answerType,
	answers,
	mode = "survey",
	video,
}) => {
	const dispatch = useDispatch();
	const [showOtherTextbox, setShowOtherTextbox] = useState(false);
	const [userResponse, setUserResponse] = useState("");
	const [other, setOther] = useState("");

	const handleSubmit = () => {
		const submission = showOtherTextbox ? other : userResponse;
		if (mode === "chat") {
			dispatch(chatSubmit(submission));
		} else if (mode === "voice") {
			dispatch(voiceSubmit(submission));
		} else {
			dispatch(surveySubmit(submission, mode));
		}
		setUserResponse("");
		setOther("");
		setShowOtherTextbox(false);
	};

	return (
		<div style={{ width: "69%", paddingTop: 15 }}>
			<Headline style={{ marginBottom: 25 }}>{HTMLText(question)}</Headline>
			<Video video={video} />
			{answerType === "list" && answers.length <= 8 && (
				<>
					<RadioButtons
						value={userResponse}
						onChange={(e) => {
							if (e.target.value === "Other") {
								setShowOtherTextbox(true);
							} else {
								setShowOtherTextbox(false);
							}
							setUserResponse(e.target.value);
						}}
						onDoubleClick={(e) => {
							handleSubmit();
						}}
						options={answers}
						optionValue="optionName"
						optionKey="value"
					/>
					{showOtherTextbox && (
						<TextInput
							value={userResponse}
							onChange={(e) => setUserResponse(e.target.value)}
							style={{ marginTop: 25 }}
						/>
					)}
				</>
			)}
			{answerType === "list" && answers.length > 8 && (
				<>
					<DropDown
						value={userResponse}
						onChange={(e) => {
							if (e.target.value === "Other") {
								setShowOtherTextbox(true);
							} else {
								setShowOtherTextbox(false);
							}
							setUserResponse(e.target.value);
						}}
						options={answers}
						optionValue="optionName"
					/>
					{showOtherTextbox && (
						<TextInput
							value={other}
							onChange={(e) => setOther(e.target.value)}
							style={{ marginTop: 25 }}
						/>
					)}
				</>
			)}
			{answerType === "raw" && (
				<TextInput
					value={userResponse}
					onChange={(e) => setUserResponse(e.target.value)}
				/>
			)}
			{answerType === "range" && <Slider />}
			<Button text="Submit" onClick={handleSubmit} disabled={!userResponse} />
		</div>
	);
};

export default SurveyView;

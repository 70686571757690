import axios from "axios";
import { firebaseApp } from "./firebase";

const DOMAINS = {
	local: {
		erx: process.env.REACT_APP_API_URL || "http://localhost:5000",
		tcs: "http://localhost:3000",
	},
	staging: {
		erx: "https://apistaging.engagementrx.wearetuzag.com",
		tcs: "https://api.stagingerx.tuzagtcs.com",
	},
	prod: {
		erx: "https://api.engagementrx.wearetuzag.com",
		tcs: "https://api.engagementrx.tuzagtcs.com",
	},
};
const baseURL = DOMAINS[process.env.REACT_APP_ENV].erx;

const API = async (url, method, data = {}, errorCatcher, formData) => {
	try {
		let response;
		let timezone;

		try {
			timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
		} catch {
			//ignore
		}

		const token = await getAuthToken();

		if (formData) {
			response = await axios({
				method,
				url: `${baseURL}${url}`,
				data: formData,
				headers: {
					"Content-Type": "multipart/form-data",
					apiKey: "Uc9zLKg6QvF8fJwwuhMsJrQvB2DMuTo4IVbJA5nE",
					timezone,
					token,
				},
			});
		} else {
			response = await axios({
				method,
				url: `${baseURL}${url}`,
				data: method === "GET" ? null : JSON.stringify(data),
				headers: {
					"Content-Type": "application/json",
					apiKey: "Uc9zLKg6QvF8fJwwuhMsJrQvB2DMuTo4IVbJA5nE",
					timezone,
					token,
				},
			});
		}
		return response.data;
	} catch (e) {
		if (errorCatcher) {
			errorCatcher(e);
		}
		console.error(e);
		throw e;
	}
};

export default API;

/**
 * @func getAuthToken - Lazy auth token from firebase whenever it's available
 * @returns {Promise<String|undefined>}
 */
const getAuthToken = async () => {
	const user = firebaseApp.auth().currentUser;
	return !user ? undefined : await user.getIdToken();
};

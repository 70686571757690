import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import { updateStateHOC } from "./reducers";

const rootReducer = combineReducers({
	chatReducer: updateStateHOC("chat"),
	surveyReducer: updateStateHOC("survey"),
	voiceReducer: updateStateHOC("voice"),
	uiReducer: updateStateHOC("ui"),
	auth: updateStateHOC("auth"),
});

const middleware = [thunk];

export const store = createStore(
	rootReducer,
	composeWithDevTools(applyMiddleware(...middleware))
);

export default store;

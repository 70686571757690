import API from "../API";
import { sendError, updateState } from "./sharedActions";

const bytesToUrl = (bytes) => {
	const buffer = new Uint8Array(bytes.length);
	buffer.set(new Uint8Array(bytes), 0);
	return URL.createObjectURL(new Blob([buffer], { type: "text/plain" }));
};

export const startVoice = (startShapeID) => async (dispatch) => {
	try {
		const res = await API("/voice", "POST", {
			start: true,
			startShapeID,
		});
		res.blobURL = bytesToUrl(res.audio.data.data);
		const { name } = await API(`/user/currentMessageName`, "GET");
		return dispatch(updateState("voice", { ...res, currentMessageName: name }));
	} catch (err) {
		return dispatch(sendError(err, "voice"));
	}
};

export const voiceSubmit = (voiceRecording) => async (dispatch) => {
	try {
		let res;
		if (!(voiceRecording instanceof Blob)) {
			res = await API("/voice", "POST", {
				stringResponse: true,
				message: voiceRecording,
			});
		} else {
			const formData = new FormData();
			formData.append("file", voiceRecording);
			formData.append("requestedElements", JSON.stringify([]));

			res = await API("/voice", "POST", null, null, formData);
		}
		if (res.audio?.data?.data) {
			res.blobURL = bytesToUrl(res.audio.data.data);
		}
		const { name } = await API(`/user/currentMessageName`, "GET");
		return dispatch(updateState("voice", { ...res, currentMessageName: name }));
	} catch (err) {
		return dispatch(sendError(err, "voice"));
	}
};
